import { AccountsService } from './../../services/resources/accounts.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {

  isLoading: boolean = false
  data: any = {}
  page: number = 1;
  total: number = 0
  itemsPerPage: number = 7
  subscription: Subscription
  
  constructor(private accountsService: AccountsService) { }

  ngOnInit(): void {
    this.getAccounts(1)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  getAccounts(page: number){    
    this.isLoading = true
    let data = {"page":page,"count":this.itemsPerPage,"sortBy":"id","direction":"desc"}
    this.subscription = this.accountsService.post(data)
    .pipe(take(1), finalize(() => this.isLoading = false))
    .subscribe(
      res => {
        this.page = page
        this.data = res['data']   
        this.total = res['total']     
      }
    )
  }

}
