import { Injectable } from '@angular/core';
import { CentralService } from '../central.service';

@Injectable({
  providedIn: 'root'
})


export class DashboardService extends CentralService {
  protected resource: string = "dashboard";
}
