<ng-container *ngIf="percentage != null">
  <div class="position-relative w-100 text-center">
    <ngx-charts-pie-chart
      [scheme]="colorScheme"
      [results]="single"
      (window:resize)="onResize($event)"
      [doughnut]="doughnut"
      [gradient]="gradient"
      [view]="view"
      [arcWidth]="arcWidth"
    >
    </ngx-charts-pie-chart>
    <div class="donut-inner d-flex justify-content-center align-items-center">
      <h2 class="d-inline-block ms-2">
        <span class="main-text" style="letter-spacing: -3px">
          <span
            appCountUp
            [endVal]="percentage"
            [duration]="5"
            [useGrouping]="true"
          ></span>
        </span>
      </h2>
      <sub>%</sub>
    </div>
  </div>
</ng-container>

