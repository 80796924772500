import { Injectable } from '@angular/core';
import { CentralService } from '../central.service';

@Injectable({
  providedIn: 'root'
})

export class ExtendService extends CentralService {
  protected resource: string = "user/extend";
}
