<div class="row mb-5">
    <div class="col-lg-12">
        <div class="card shadow-sm hover-shadow transition-fast rounded-lg mb-5">
            <div class="card-body p-5 px-0 px-lg-4 py-lg-4">
                <div class="container text-start">
                    <div class="row mb-3">
                        <div class="col-12">
                            <h4 class="font-weight-bold text-start">البيانات الشخصية</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-6 my-3">
                            <label class="w-100">
                                <span class="ms-4 ">الاسم</span>
                                <input type="text" class="form-control rounded-pill mt-2 px-4" [value]="((userProfile?.firstname || '') + (userProfile?.lastname || '')) || 'غير محدد'" readonly>
                            </label>
                        </div>
                        <div class="col-xl-6 my-3">
                            <label class="w-100">
                                <span class="ms-4 ">رقم العقد</span>
                                <input type="text" class="form-control rounded-pill mt-2 px-4" [value]="userProfile?.contract_id || 'غير محدد'" readonly>
                            </label>
                        </div>
                        <div class="col-xl-6 my-3">
                            <label class="w-100">
                                <span class="ms-4 ">البريد الإلكتروني</span>
                                <input type="text" class="form-control rounded-pill mt-2 px-4" [value]="userProfile?.email || 'غير محدد'" readonly>
                            </label>
                        </div>
                        <div class="col-xl-6 my-3">
                            <label class="w-100">
                                <span class="ms-4 ">رقم الهاتف</span>
                                <input type="text" class="form-control rounded-pill mt-2 px-4" [value]="userProfile?.phone || 'غير محدد'" readonly>
                            </label>
                        </div>
                        <!-- <div class="col-xl-3 my-3">
                            <label class="w-100">
                                <span class="ms-4 ">رقم الهاتف الثاني</span>
                                <input type="text" class="form-control rounded-pill mt-2 px-4" [value]="userProfile?.contract_id" readonly>
                            </label>
                        </div> -->
                        <div class="col-xl-6 my-3">
                            <label class="w-100">
                                <span class="ms-4 ">العنوان</span>
                                <input type="text" class="form-control rounded-pill mt-2 px-4" [value]="userProfile?.address || 'غير محدد'" readonly>
                            </label>
                        </div>
                        <div class="col-xl-6 my-3">
                            <label class="w-100">
                                <span class="ms-4 ">تاريخ الإشتراك</span>
                                <input type="text" class="form-control rounded-pill mt-2 px-4" [value]="(userProfile?.registered_on?.date | date:'YYYY/MM/dd - hh:mm a') || 'غير محدد'" readonly>
                            </label>
                        </div>                                                
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
