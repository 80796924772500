import { Injectable } from '@angular/core';
import { CentralService } from '../central.service';

@Injectable({
  providedIn: 'root'
})

export class SessionService extends CentralService {
  protected resource: string = "index/session";
}
