import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

  mode: string = 'show'
  isChatEmpty: boolean = false

  constructor(private route: ActivatedRoute) { 
    let id = this.route.snapshot.params.id;
    if(id) {
      // alert(id)
      this.isChatEmpty = false
      console.log('chat not empty!');

    } else {
      // if data empty
      this.isChatEmpty = true
    }
  }

  ngOnInit(): void {
    
  }

}
