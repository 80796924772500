import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as shape from 'd3-shape';
import { FormatBytesToGigaBytesPipe } from 'src/app/pipes/format-bytes-to-gigabytes.pipe';
@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.scss']
})
export class AreaComponent implements OnInit {

  @Input() data: [] = []

  multi: any = [
    {
      "name": "الإستهلاك (GB)",
      "series": []
    },
    {
      "name": "الإستهلاك المجاني (GB)",
      "series": []
    }
  ];

  view: any[] = [];

  // options
  legend: boolean = true;
  legendTitle: string = ""
  legendPosition: string = "below"
  showLabels: boolean = false;
  animations: boolean = false;
  xAxis: boolean = true;
  yAxis: boolean = true;
  autoScale: boolean = false
  yScaleMin: number = 0
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'Population';
  timeline: boolean = false;
  linearCurve = shape.curveCardinal;
  colorScheme = {
    domain: ['#cf1846','#4da74d'],
  };

  isTolalEqualZero: number = 0

  areaChartContainerWidth: number
  @ViewChild('areaChartContainer') elementView: ElementRef;

  constructor(private formatBytesToGigaBytesPipe: FormatBytesToGigaBytesPipe) {}

  ngOnInit(): void {
    this.fillChartData()
  }

  fillChartData() {
    for(let i = 0; i < this.data.length; i++) {
      let rowFree = {"value":this.formatBytesToGigaBytes(this.data[i]['total']),"name":i+1}
      this.multi[0]['series'].push(rowFree)

      let rowTotal = {"value":this.formatBytesToGigaBytes(this.data[i]['free_traffic']),"name":i+1}
      this.multi[1]['series'].push(rowTotal)
 
      this.isTolalEqualZero += Number(this.data[i]['total']) + Number(this.data[i]['free_traffic'])
    }
  }

  ngAfterViewInit(): void {
    if(this.isTolalEqualZero) {      
      this.areaChartContainerWidth = this.elementView.nativeElement.offsetWidth;
      setTimeout(() => {
        this.calculateChartSize();
      }, 0);
    }
  }

  onResize(event) {    
    this.calculateChartSize();
  }

  calculateChartSize() {
    if (innerWidth < 700) {
      this.view = [this.areaChartContainerWidth + 25, innerHeight / 4];
    } else {
      this.view = [this.areaChartContainerWidth, innerHeight / 3];
    }
  }

  formatBytesToGigaBytes(value): Number {
    return Number(this.formatBytesToGigaBytesPipe.transform(value).toString().split('|*|')[0])
  }

}
