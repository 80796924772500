<div class="row mb-5">
    <div class="col-lg-12">
        <div class="card shadow-sm hover-shadow transition-fast rounded-lg mb-5">
            <div class="card-body p-5 px-0 px-lg-4 py-lg-4">
                <div class="container text-start">
                    <div class="row mb-3">
                        <div class="col-12">
                            <h4 class="font-weight-bold text-center text-lg-start">سجل الحسابات</h4>
                        </div>
                    </div>
                    <ng-container *ngIf="isLoading">
                        <div class="d-flex justify-content-center align-items-center my-5">
                            <div class="spinner-grow" style="width: 2rem; height: 2rem;" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </ng-container>                    
                    <ng-container *ngIf="!isLoading">
                        <div>
                            <div class="table-responsive scroll-x">
                                <table class="table table-bordered table-hover align-middle">
                                    <thead class="bg-gray text-center">
                                        <tr>
                                            <th class="py-2" scope="col">التاريخ</th>
                                            <th class="py-2" scope="col">دائن</th>
                                            <th class="py-2" scope="col">مدين</th>
                                            <th class="py-2" scope="col">القيمة</th>
                                            <th class="py-2" scope="col">الرصيد</th>
                                            <th class="py-2" scope="col">العملية</th>
                                            <th class="py-2" scope="col">تفاصيل</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-center font-weight-normal">
                                        <tr *ngIf="!data.length">
                                            <td colspan="7">
                                                 لا توجد بيانات لعرضها!
                                            </td>
                                        </tr>                                        
                                        <tr *ngFor="let item of data | paginate: {itemsPerPage:itemsPerPage, currentPage: page, totalItems: total}">
                                            <td class="py-3" scope="row">{{item['created_at']}}</td>
                                            <td class="py-3">{{item['cr'] | replace : '_system_user' : 'الأفق الرقمي'}}</td>
                                            <td class="py-3">{{item['dr'] | replace : '_system_user' : 'الأفق الرقمي'}}</td>
                                            <td class="py-3">{{item['amount']}} د.ل</td>
                                            <td class="py-3">{{item['balance']}} د.ل</td>
                                            <td class="py-3">{{item['operation'] || 'غير محدد'}}</td>
                                            <td class="py-3">{{item['description']}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center mt-2">
                            <pagination-controls (pageChange)="getAccounts($event)" nextLabel="" previousLabel=""></pagination-controls>
                        </div>                        
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>