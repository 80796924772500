
<div id="wrapper" class="d-flex align-items-center justify-content-center">
    <div class="container">
        <div class="row">
            <div class="col-11 col-md-8 col-lg-4 px-lg-2 mx-auto">
                <ng-container>
                    <div class="row">
                        <div class="col-12">
                            <div class="card shadow-sm hover-shadow transition-fast" style="border-radius: 10px;background: #ffffff;">
                                <div class="card-body py-2 font-weight-bold text-start" style="font-weight: 500;">
                                    <div class="row">
                                        <ng-container>
                                            <div class="col-12">
                                                <small class="d-block my-1" id="location-access-message">
                                                    <i class="fa fa-exclamation-triangle pe-1 text-yellow"></i>
                                                    يرجى تمكين الوصول إلى الموقع حتى تتمكن من تسجيل الدخول التلقائي.
                                                </small>
                                            </div>
                                        </ng-container>                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            <div> 
        </div>
                <div class="card rounded-lg shadow-sm hover-shadow transition-fast mt-4 mb-5 pb-5 mb-lg-0 pb-lg-0">
                    <div class="card-body px-sm-5 py-sm-5 py-lg-3 px-lg-5 pb-lg-3">
                        <div class="text-center">
                            <img class="img-fluid" src="/assets/img/alofoq_logo_main.png" width="250"
                                alt="الأفق الرقمي - بوابة المشتركين" title="الأفق الرقمي - بوابة المشتركين">
                            <h6 class="font-weight-bold text-dark">بوابة المشتركين</h6>
                        </div>
                        <div class="mt-4 mt-sm-5">
                            <form #loginForm="ngForm" (ngSubmit)="login(loginForm)">
                                <div class="row gy-4">
                                    <div class="col-12">
                                        <label class="w-100">
                                            <small>اسم المستخدم/رقم الهاتف</small>
                                            <input
                                                type="text"
                                                class="form-control pt-1 pb-2 rounded-lg mt-2"
                                                placeholder="ادخل اسم المستخدم"
                                                [(ngModel)]="user.username"
                                                #username="ngModel"
                                                name="username"
                                                type="text"
                                                required
                                            >
                                            <small *ngIf="
                                                (username.errors?username.errors['required']:false)
                                                && username.touched" class="text-danger">
                                                الرجاء إدخال اسم المستخدم
                                            </small>
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <label class="w-100">
                                            <small>كلمة المرور</small>
                                            <input
                                                type="password"
                                                class="form-control pt-1 pb-2 rounded-lg mt-2"
                                                placeholder="ادخل كلمة المرور"
                                                [(ngModel)]="user.password"
                                                #password="ngModel"
                                                name="password"
                                                type="password"
                                                required
                                            >
                                        </label>
                                        <small *ngIf="
                                        (password.errors?password.errors['required']:false)
                                        && password.touched" class="text-danger">
                                        الرجاء إدخال كلمة المرور!
                                        </small>                                       
                                    </div>
                                    <div class="col-12 mt-5 d-flex justify-content-center">
                                        <button type="submit" class="btn btn-login pt-0 rounded-pill px-4" [disabled]="isLoading">
                                            تسجيل الدخول
                                            <ng-container *ngIf="isLoading">
                                                <span class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                                            </ng-container>                                        
                                        </button>
                                    </div>
                                    <div class="col-12 mt-4 d-flex justify-content-center">
                                        <img src="/assets/img/router-bg.png" class="img-fluid w-75" title="تسجيل الدخول">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>