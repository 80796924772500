<main id="pageContent">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-1 px-0 m-h-100-vh bg-white shadow z-index-1 d-none d-xl-block">
                <app-sidebar></app-sidebar>
            </div>
            <div class="col-xl-11 px-0 position-relative">
                <app-top-nav></app-top-nav>
                <div class="bg-gray h-100 pt-5">
                    <div class="container pt-3 pt-xl-1">
                        <div class="row pt-5 pt-xl-4 text-center">
                            <div class="col-12">
                                <div class="mt-xl-3 px-xl-5 mx-xl-5">
                                    <router-outlet></router-outlet>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-bottom-nav></app-bottom-nav>
            </div>
        </div>
    </div>
</main>