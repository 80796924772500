<div class="row mb-5">
    <div class="col-lg-12">
        <div class="card shadow-sm hover-shadow transition-fast rounded-lg mb-5">
            <div class="card-body p-5 px-0 px-lg-4 py-lg-4">
                <div class="container text-start">
                    <div class="row justify-content-end mt-4 mt-lg-0">
                        <div class="col-lg-6">
                            <div class="row gy-5">
                                <div [ngClass]="report_type == 'monthly' ? 'col-12 col-lg-6' : 'col-6 col-lg-3'"> 
                                    <select 
                                        class="form-select rounded-pill hover-shadow transition-fast"
                                        name="reportType"
                                        [(ngModel)]="report_type"
                                        (change)="selectedMonth = currentMonth;getData()"
                                    >
                                        <option value="daily">يومي</option>
                                        <option value="monthly">شهري</option>
                                    </select>
                                </div>
                                <div class="col-6 col-lg-3" *ngIf="report_type!='monthly'">
                                    <select
                                        class="form-select rounded-pill hover-shadow transition-fast"
                                        name="month"
                                        [(ngModel)]="selectedMonth"
                                        (change)="getData()"
                                    >
                                        <option value="1">يناير</option>
                                        <option value="2">فبراير</option>
                                        <option value="3">مارس</option>
                                        <option value="4">ابريل</option>
                                        <option value="5">مايو</option>
                                        <option value="6">يونيو</option>
                                        <option value="7">يوليو</option>
                                        <option value="8">أغسطس</option>
                                        <option value="9">سبتمبر</option>
                                        <option value="10">أكتوبر</option>
                                        <option value="11">نوفمبر</option>
                                        <option value="12">ديسمبر</option>
                                    </select>
                                </div>
                                <div class="col-10 col-lg-4">
                                    <select
                                        class="form-select rounded-pill hover-shadow transition-fast" 
                                        name="year"
                                        [(ngModel)]="selectedYear"
                                        (change)="getData()"
                                    >
                                        <ng-container  *ngFor="let item of years">
                                            <option [value]="item">{{item}}</option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div class="col-2 col-lg-2 text-center">
                                    <button (click)="getData()" class="btn btn-light bg-white shadow-sm hover-shadow btn-sm rounded-circle px-1 pt-1 pb-0" title="تحديث">
                                        <span class="material-icons">loop</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="isLoading">
                        <div class="d-flex justify-content-center align-items-center my-5 py-5">
                            <div class="spinner-grow my-5" style="width: 2rem; height: 2rem;" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!isLoading">
                        <div class="mt-5 mb-3">
                            <div class="row">
                                <div class="col-12">
                                    <ng-container *ngIf="chartData.length">
                                      <app-area [data]="chartData"></app-area>
                                    </ng-container>
                                    
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!isLoading">
                        <div class="table-responsive mt-5">
                            <table class="table table-bordered table-hover align-middle">
                                <thead class="bg-gray text-center">
                                    <tr>
                                        <th class="py-2" scope="col">التاريخ</th>
                                        <th class="py-2" scope="col">التنزيل</th>
                                        <th class="py-2" scope="col">الرفع</th>
                                        <th class="py-2" scope="col">الإجمالي</th>
                                        <th class="py-2" scope="col">المجاني</th>
                                    </tr>
                                </thead>
                                <tbody class="text-center font-weight-normal">
                                    <tr *ngIf="!tableData.length">
                                        <td colspan="5">
                                             لا توجد بيانات لعرضها!
                                        </td>
                                    </tr> 
                                    <ng-container *ngFor="let item of tableData; let i = index">
                                      <tr>
                                          <td class="py-3" scope="row">
                                         

                                                   <div>
                                                    <ng-container *ngIf="report_type == 'monthly'">
                                                        {{selectedYear}}-{{item['day']}}
                                                    </ng-container>
                                                    <ng-container *ngIf="report_type == 'daily'">
                                                        {{selectedYear}}-{{selectedMonth}}-{{item['day']}}
                                                    </ng-container>
                                                  </div>


                                          </td>
                                          <td class="py-3" dir="ltr">
                                              {{(item['rx'] | formatBytes) | split: '|*|':0}}
                                              <small>{{(item['rx'] | formatBytes) | split: '|*|':1}}</small>
                                          </td>
                                          <td class="py-3" dir="ltr">
                                            {{(item['tx'] | formatBytes) | split: '|*|':0}}
                                            <small>{{(item['tx'] | formatBytes) | split: '|*|':1}}</small>
                                          </td>
                                          <td class="py-3" dir="ltr">
                                            {{(item['total'] | formatBytes) | split: '|*|':0}}
                                            <small>{{(item['total'] | formatBytes) | split: '|*|':1}}</small>
                                          </td>
                                          <td class="py-3" dir="ltr">
                                            {{((item['free_traffic']) | formatBytes) | split: '|*|':0}}
                                            <small>{{((item['free_traffic']) | formatBytes) | split: '|*|':1}}</small>
                                          </td>
                                      </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>                    
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>