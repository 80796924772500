<!-- data-bs-backdrop="static" -->
<div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" [id]="modalName" tabindex="-1" aria-labelledby="changePackageModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div #modalContent class="modal-content glassmorphism-card rounded-lg px-2 px-lg-5 py-3">
            <div class="modal-header border-bottom ">
                <h4 class="modal-title font-weight-bold" id="changePackageModalLabel">العروض</h4>
                <button (click)="changeStatusToClosed(modalName)" #closeButton type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" title="إغلاق"></button>
            </div>
            <div class="modal-body pt-0">
                <div class="">
                    <app-loader-spinner [color]="'success'" *ngIf="isLoading"></app-loader-spinner>
                    <ng-container *ngIf="!isLoading">
                        <ng-container *ngIf="step == 1">
                            <div class="container">
                                <div class="row h-100 mt-4">
                                    <ng-container *ngIf="offers.length == 0">
                                        <div class="col-12">
                                            <img src="assets/img/empty.png" alt="لا توجد بعروض متاحة">
                                        </div>
                                    </ng-container>
                                    <ng-container *ngFor="let item of offers">
                                        <div class="col-12 col-lg-4 my-2">
                                            <div
                                            class="card cursor-pointer hover-shadow transition-fast rounded-lg h-100 scale-up-hover" style="min-height: 130px;"
                                            [title]="item?.name"
                                            (click)="selectedOffer = item;changeStep(2)"
                                            >
                                            <div class="card-body">
                                                <div class="d-flex align-items-center justify-content-center h-100 my-lg-1">
                                                    <div class="row">
                                                        <div class="col-12 p-0">
                                                            <img class="img-fluid img-offer" [src]="('cdnBase' | env) + 'img/ucp/offers/'+item['id']+'.png'" onerror="this.src='assets/img/transparent_offer.png';">
                                                            <div class="text-offer position-absolute w-100 h-100">
                                                                <div class="d-flex justify-content-center align-items-center w-100 h-100">
                                                                    <h6 class="mb-0">{{item['name']}}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="step == 2">
                            <div class="row mt-4">
                                <div class="col-12">
                                    <h5>هل انت متأكد من تفعيل <span class="text-success">{{selectedOffer['name']}}</span>؟</h5>
                                    <button (click)="changeStep(1)" class="btn btn-outline-dark w-auto rounded-pill mx-auto pt-0 px-4 mt-2 me-2" [disabled]="isLoading">تراجع</button>                                    
                                    <button (click)="activateOffer()" class="btn btn-success w-auto rounded-pill mx-auto pt-0 px-4 mt-2" [disabled]="isLoading">تفعيل العرض</button>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>           
            </div>
        </div>
    </div>
</div>