<div id="pc" class="py-3 position-absolute w-100 bg-white border-bottom d-none d-md-block">
    <div class="px-xl-5 mx-xl-5">
        <div class="container px-xl-5">
            <div class="row px-xl-3">
                <div class="col-md-4">
                    <div class="row">
                        <div class="col-md-5 col-xl-2">
                            <div class="d-none d-md-inline d-xl-none pe-3">
                                <app-mobile-sidebar></app-mobile-sidebar>
                            </div>
                            <a routerLink="/profile" class="link-dark">
                                <img src="assets/img/profile_picture.png" alt="user image"
                                    width="40" height="40" [title]="(this.userProfile['firstname'] || '') + ' ' + (this.userProfile['lastname'] || '')" onerror="this.onerror=null;this.src='assets/img/on_error.jpg'">
                            </a>
                        </div>
                        <div class="col-md-7 col-xl-10">
                            <div class="d-flex h-100 align-items-center">
                                <div class="w-100 ms-1">
                                    <h6 class="mb-0 font-weight-bold mb-0 w-auto" style="font-size: smaller">
                                        <a routerLink="/profile" class="link-dark">
                                            <small *ngIf="!isLoading" title="اسم المشترك">{{(this.userProfile['firstname'] || '') + ' ' + (this.userProfile['lastname'] || '')}}</small>
                                            <small *ngIf="isLoading" class="w-50 d-block mt-1 mb-2">
                                                <ngx-skeleton-loader class="skeleton-small" count="1" appearance="line"></ngx-skeleton-loader>
                                            </small>
                                        </a>
                                    </h6>
                                    <a routerLink="/profile" class="link-dark">
                                        <small *ngIf="!isLoading" class="font-weight-normal" title="اسم المستخدم">{{this.userProfile['username']}}</small>
                                        <small *ngIf="isLoading" class="w-25 d-block">
                                            <ngx-skeleton-loader class="skeleton-small" count="1" appearance="line"></ngx-skeleton-loader>
                                        </small>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="text-center">
                            <h6 class="mb-0 mt-1">
                                <a class="link-dark" routerLink="/">
                                    <small class="d-block font-weight-bold">الأفق الرقمي لخدمات الإنترنت</small>
                                    <small>بوابة المشتركين</small>
                                </a>
                            </h6>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="h-100 d-flex align-items-center justify-content-end">
                        <h6 class="text-center">
                            <button class="btn btn-sm btn-outline-dark rounded-pill px-3 pt-0 float-end" (click)="logout()" [title]="'تسجيل خروج المستخدم ' + this.userProfile['username']">
                                <small><i class="fa fa-power-off pe-1"></i></small>
                                تسجيل الخروج 
                            </button>
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="mobile" class="py-3 position-absolute w-100 bg-white border-bottom d-md-none">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-4 text-start">
                <app-mobile-sidebar [userProfile]="this.userProfile"></app-mobile-sidebar>
                <img src="assets/img/alofoq_logo_icon.png" class="img-fluid scale-up-hover transition-fast w-50 ms-1"
                alt="Alofoq Logo" title="الأفق الرقمي لخدمات الإنترنت"
                onerror="this.onerror=null;this.src='assets/img/on_error.jpg'">
            </div>
            <div class="col-4 px-0">
                <div class="text-center">
                    <a class="link-dark" routerLink="/">
                        <h6 class="font-weight-bold mb-0">
                            <a class="link-dark" routerLink="/">
                                <small>بوابة المشتركين</small>
                            </a>
                        </h6>
                    </a>
                </div>
            </div>                
            <div class="col-4 text-end">
                <a routerLink="/profile" class="link-dark">

                    <div class="w-100">
                        <h6 class="mb-0 font-weight-bold mb-0 w-auto" style="font-size: smaller">
                            <a routerLink="/profile" class="link-dark">
                                <small *ngIf="!isLoading" title="اسم المشترك">{{(this.userProfile['firstname'] || '') + ' ' + (this.userProfile['lastname'] || '')}}</small>
                                <small *ngIf="isLoading" class="w-50 d-block mt-1 mb-2">
                                    <ngx-skeleton-loader class="skeleton-small" count="1" appearance="line"></ngx-skeleton-loader>
                                </small>
                            </a>
                        </h6>
                        <a routerLink="/profile" class="link-dark">
                            <small *ngIf="!isLoading" class="font-weight-normal" title="اسم المستخدم">{{this.userProfile['username']}}</small>
                            <small *ngIf="isLoading" class="w-25 d-block">
                                <ngx-skeleton-loader class="skeleton-small" count="1" appearance="line"></ngx-skeleton-loader>
                            </small>
                        </a>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
