<div class="row mb-5">
    <div class="col-lg-12">
        <div class="card shadow-sm hover-shadow transition-fast rounded-lg mb-5">
            <div class="card-body p-5 px-0 px-lg-4 py-lg-4">
                <div class="container text-start">
                    <div class="row">
                        <div class="col-12">
                            <h4 class="font-weight-bold text-center text-lg-start">الموزعين</h4>
                        </div>
                    </div>
                    
                    <ng-container *ngIf="isLoading">
                        <div class="d-flex justify-content-center align-items-center my-5 py-5">
                            <div class="spinner-grow my-5" style="width: 2rem; height: 2rem;" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="parsedSupplliers">
                        <div class="row justify-content-end">
                            <div class="col-lg-3">
                                <div class="row gy-5">
                                    <div class="col-12">
                                        <select
                                            class="form-select rounded-pill hover-shadow transition-fast mt-5 mt-lg-0"
                                            name="selectedSupplier"
                                            [(ngModel)]="selectedSupplier"
                                         >
                                            <ng-container *ngFor="let city of parsedSupplliers | keyvalue">
                                                <option [value]="city.key">{{city.key}}</option>
                                            </ng-container>
                                        </select>
                                    </div>                                
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    
                    
                    <ng-container *ngIf="selectedSupplier && parsedSupplliers[selectedSupplier].length > 0">
                        <div class="table-responsive mt-5">
                            <table class="table table-bordered table-hover align-middle">
                                <thead class="bg-gray text-center">
                                    <tr>
                                        <th class="py-2" scope="col">#</th>
                                        <th class="py-2" scope="col">الاسم</th>
                                        <th class="py-2" scope="col">العنوان</th>
                                    </tr>
                                </thead>
                                <tbody class="text-center font-weight-normal">
                                    <ng-container *ngFor="let supplier of parsedSupplliers[selectedSupplier]; let i = index">
                                        <tr>
                                            <td class="py-3" scope="row">{{i+1}}</td>
                                            <td class="py-3" scope="row">{{supplier['name']}}</td>
                                            <td class="py-3">{{supplier['address']}}</td>
                                        </tr>
                                    </ng-container>  
                                </tbody>
                            </table>
                        </div>                       
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>