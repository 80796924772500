<div class="pt-3 text-center">
    <a routerLink="/">
        <img src="assets/img/alofoq_logo_icon.png" class="img-fluid scale-up-hover transition-fast" width="60px"
            alt="Alofoq Logo" title="الأفق الرقمي لخدمات الإنترنت"
            onerror="this.onerror=null;this.src='assets/img/on_error.jpg'">
    </a>
    <div class="menu-container mt-3">
        <div class="menu">
            <a routerLink="/" class="d-block link-dark link-sidebar py-3" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
                <span class="material-icons">home</span>
                <h6 class="mb-0"><small>الرئيسية</small></h6>
            </a>
            <a routerLink="/service-log" class="d-block link-dark link-sidebar py-3" routerLinkActive="active">
                <span class="material-icons">history</span>
                <h6 class="mb-0"><small>السجل</small></h6>
            </a>
            <a routerLink="/usage-log" class="d-block link-dark link-sidebar py-3" routerLinkActive="active">
                <span class="material-icons">bar_chart</span>
                <h6 class="mb-0"><small>الإستخدام</small></h6>
            </a>
            <a routerLink="/accounts" class="d-block link-dark link-sidebar py-3" routerLinkActive="active">
                <span class="material-icons">payments</span>
                <h6 class="mb-0"><small>الحسابات</small></h6>
            </a>
            <a routerLink="/session-log" class="d-block link-dark link-sidebar py-3" routerLinkActive="active">
                <span class="material-icons">sync_alt</span>
                <h6 class="mb-0"><small>الجلسات</small></h6>
            </a>
            <a routerLink="/help" class="d-block link-dark link-sidebar py-3" routerLinkActive="active">
                <span class="material-icons">support</span>
                <h6 class="mb-0"><small>المساعدة</small></h6>
            </a>
            <a routerLink="/suppliers" class="d-block link-dark link-sidebar py-3" routerLinkActive="active">
                <span class="material-icons">storefront</span>
                <h6 class="mb-0"><small>الموزعين</small></h6>
            </a>
            <a routerLink="/speed-test" class="d-block link-dark link-sidebar py-3" routerLinkActive="active">
                <span class="material-icons">speed</span>
                <h6 class="mb-0"><small>السرعة</small></h6>
            </a>            
        </div>
    </div>
</div>