<div class="row mb-5">
  <div class="col-lg-12">
    <div class="card shadow-sm hover-shadow transition-fast rounded-lg mb-5">
      <div class="card-body p-5 px-0 px-lg-4 py-lg-4">
        <div class="container text-start">
          <div class="row mb-3">
            <div class="col-12">
              <h4 class="font-weight-bold text-center text-lg-start">الأسئلة المتكررة</h4>
            </div>
          </div>



          <div class="accordion " id="QA">
            <!-- <div class="accordion-item">
              <h2 class="accordion-header" id="questionHeading-0">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#question-collapse-0" aria-expanded="false" aria-controls="question-collapse-0">
                  Accordion Item #1
                </button>
              </h2>
              <div id="question-collapse-0" class="accordion-collapse collapse" aria-labelledby="questionHeading-0"
                data-bs-parent="#QA">
                <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the
                  <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
              </div>
            </div> -->

            <div class="accordion-item">
              <h2 class="accordion-header" id="questionHeading-1">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#question-collapse-1" aria-expanded="false" aria-controls="question-collapse-1">
                  مالفرق بين باقات Leased Line و باقات Shared؟
                </button>
              </h2>
              <div id="question-collapse-1" class="accordion-collapse collapse" aria-labelledby="questionHeading-1"
                data-bs-parent="#QA">
                <div class="accordion-body">
                  بالنسبة لباقات Leased Line وهي خط مخصص، فإن السرعة تظل ثابتة طوال الوقت أما باقات Shared هي خط مشترك
                  أي ان السرعة تتأثر نسبياَ في اوقات الذروة.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="questionHeading-2">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#question-collapse-2" aria-expanded="false" aria-controls="question-collapse-2">
                  مدة الضمان على الاجهزة؟
                </button>
              </h2>
              <div id="question-collapse-2" class="accordion-collapse collapse" aria-labelledby="questionHeading-2"
                data-bs-parent="#QA">
                <div class="accordion-body">
                  الضمان على الأجهزة يكون لمدة شهر واحد من تاريخ التركيب ولا يشمل هذا الضمان الأعطال الكهربائية والصواعق
                  والأضرار الناتجة عن سوء الاستخدام.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="questionHeading-3">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#question-collapse-3" aria-expanded="false" aria-controls="question-collapse-3">
                  كيف يمكنني التواصل مع مركز خدمات المشتركين؟
                </button>
              </h2>
              <div id="question-collapse-3" class="accordion-collapse collapse" aria-labelledby="questionHeading-3"
                data-bs-parent="#QA">
                <div class="accordion-body">
                  يمكنك التواصل مع مركز خدمات المشتركين عن طريق
                  <br><br>
                  <ul class="text-right">
                    <li class="my-3">
                      فرع بنغازي
                      <p class="text-muted">
                        هاتف:
                        0923620003 - 0923620004
                        <br>
                        العنوان:
                        منطقة الحدائق عمارات 7000 – بجانب مقر السجل العقاري
                        <br>
                        مواعيد الدعم الفني:
                        من الساعة 9 صباحاً حتى الساعة 9 مساءً
                        <br>
                        مواعيد الدوام الرسمي:
                        من 9 صباحًا حتى الساعة 4 مساءً
                      </p>
                    </li>
                    <li class="my-3">
                      فرع سرت
                      <p class="text-muted">
                        هاتف:
                        0914866600
                        <br>
                        العنوان:
                        عمارات الضمان الاجتماعي بجوار مطعم تقسيم
                        <br>
                      </p>
                    </li>
                    <li class="my-3">
                      فرع البيضاء
                      <p class="text-muted">
                        هاتف:
                        0910510200
                        <br>
                        العنوان:
                        مستشفى البيضاء بالقرب من عيادة العلاج الطبيعي
                        <br>
                      </p>
                    </li>
                    <li class="my-3">
                      فرع الكفرة
                      <p class="text-muted">
                        هاتف:
                        0917671511 - 0645211541
                        <br>
                        العنوان:
                        طريق السوسي – بالقرب من مصرف شمال افريقيا
                        <br>
                      </p>
                    </li>
                    <li class="my-3">
                      فرع طبرق
                      <p class="text-muted">
                        هاتف:
                        0915558877
                        <br>
                        العنوان:
                        شارع اسنينات – مول اصول – مقابل مصرف شمال افريقيا
                        <br>
                      </p>
                    </li>
                    <li class="my-3">
                      أو التواصل عن طريق مواقع التواصل الاجتماعي:
                      <ul>
                        <li class="my-1">
                          فيسبوك:
                          <a href="https://m.me/ALOFOQ.ly" target="_blank">@شركة الأفق الرقمي لخدمات الانترنت</a>
                        </li>

                        <li class="my-1">
                          تويتر:
                          <a href="https://twitter.com/AlofoqLY" target="_blank">@الأفق الرقمي لخدمات الإنترنت</a>
                        </li>

                        <li class="my-1">
                          فيسبوك فرع الكفرة:
                          <a href="https://www.facebook.com/Alofoq.Ly.KUFRA"
                            target="_blank">@شركة الأفق الرقمي لخدمات الأنترنت فرع الكفرة</a>
                        </li>

                        <li class="my-1">
                          فيسبوك فرع طبرق:
                          <a href="https://www.facebook.com/Alofoq.Tobruk" target="_blank">@شركة الأفق الرقمي لخدمات
                            الانترنت فرع طبرق</a>
                        </li>

                        <li class="my-1">
                          تويتر:
                          <a href="https://twitter.com/AlofoqLY" target="_blank">@الأفق الرقمي لخدمات الانترنت</a>
                        </li>

                      </ul>
                    </li>

                  </ul>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="questionHeading-4">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#question-collapse-4" aria-expanded="false" aria-controls="question-collapse-4">
                  كيف يمكن تغيير اسم وكلمة المرور الشبكة الخاصة بالراوتر؟
                </button>
              </h2>
              <div id="question-collapse-4" class="accordion-collapse collapse" aria-labelledby="questionHeading-4"
                data-bs-parent="#QA">
                <div class="accordion-body">
                  الدخول على المتصفح وكتابة 192.168.0.1 في شريط البحث.
                  <br><br>
                  <div class="row">
                    <div class="col-12 col-md-8 mx-auto">
                      <img [src]="('cdnBase' | env) + 'img/faq/pass_and_ssid.jpg'" class="img-fluid img-thumbnail">
                    </div>
                  </div>
                  <br>
                  ستظهر لك هذه الصفحة ثم قم بتغيير اسم الشبكة من خلال SSID وكلمة المرور من خلال Password ثم اضغط على زر
                  Save.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="questionHeading-5">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#question-collapse-5" aria-expanded="false" aria-controls="question-collapse-5">
                  كيف يمكنني إخفاء الشبكة؟
                </button>
              </h2>
              <div id="question-collapse-5" class="accordion-collapse collapse" aria-labelledby="questionHeading-5"
                data-bs-parent="#QA">
                <div class="accordion-body">
                  قم بالضغط على زر Advanced
                  <br><br>
                  <div class="row">
                    <div class="col-12 col-md-8 mx-auto">
                      <img [src]="('cdnBase' | env) + 'img/faq/hide2.jpg'" class="img-fluid img-thumbnail">
                    </div>
                  </div>
                  <br><br>
                  ثم قم بالدخول على Wireless وإختيار Wireless Settings ثم قم بالضغط على Disable من خلال SSID Broadcast
                  ثم اضغط على زر Save.
                  <br><br>
                  <div class="row">
                    <div class="col-12 col-md-8 mx-auto">
                      <img [src]="('cdnBase' | env) + 'img/faq/hide.jpg'" class="img-fluid img-thumbnail">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="questionHeading-6">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#question-collapse-6" aria-expanded="false" aria-controls="question-collapse-6">
                  في حالة تم إعادة ضبط المصنع لجهاز الراوتر كيف يمكنني الاتصال بالشبكة؟
                </button>
              </h2>
              <div id="question-collapse-6" class="accordion-collapse collapse" aria-labelledby="questionHeading-6"
                data-bs-parent="#QA">
                <div class="accordion-body">
                  يمكنك إيجاد اسم وكلمة مرور الشبكة في الجهة السفلية لجهاز الراوتر.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="questionHeading-7">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#question-collapse-7" aria-expanded="false" aria-controls="question-collapse-7">
                  ماذا أفعل عندما لا يتوفر إنترنت؟
                </button>
              </h2>
              <div id="question-collapse-7" class="accordion-collapse collapse" aria-labelledby="questionHeading-7"
                data-bs-parent="#QA">
                <div class="accordion-body">
                  1. التأكد من جميع التوصيلات بالكهرباء.
                  <br><br>
                  2. التأكد من أن التوصيلات صحيحة كما هو مبين في الصورة التالية:
                  <br><br>
                  <div class="row">
                    <div class="col-12 mx-auto">
                      <img [src]="('cdnBase' | env) + 'img/faq/connection-04.jpg'" class="img-fluid img-thumbnail">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="questionHeading-8">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#question-collapse-8" aria-expanded="false" aria-controls="question-collapse-8">
                  كيف يمكنني تعبئة الرصيد؟
                </button>
              </h2>
              <div id="question-collapse-8" class="accordion-collapse collapse" aria-labelledby="questionHeading-8"
                data-bs-parent="#QA">
                <div class="accordion-body">
                  1. من أعلى الواجهة الرئيسية اضغط على زر "تعبئة الرصيد".
                  <br><br>
                  <div class="row">
                    <div class="col-12 col-lg-5 col-xl-4 mx-auto">
                      <img [src]="('cdnBase' | env) + 'img/faq/deposit_1.png'" class="img-fluid img-thumbnail">
                    </div>
                  </div>
                  <br><br>
                  2. قم بإدخال رقم الكرت في الخانة المخصصة، ثم اضغط على زر " تعبئة".
                  <br><br>
                  <div class="row">
                    <div class="col-12 col-lg-5 col-xl-4 mx-auto">
                      <img [src]="('cdnBase' | env) + 'img/faq/deposit_2.png'" class="img-fluid img-thumbnail">
                    </div>
                  </div>
                  <br><br>                  
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="questionHeading-9">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#question-collapse-9" aria-expanded="false" aria-controls="question-collapse-9">
                  كيف يمكنني تغيير الباقة؟
                </button>
              </h2>
              <div id="question-collapse-9" class="accordion-collapse collapse" aria-labelledby="questionHeading-9"
                data-bs-parent="#QA">
                <div class="accordion-body">
                  1. من الواجهة الرئيسية للتطبيق، توجّه للأسفل واضغط على زر " تغيير الباقة".
                  <br><br>
                  <div class="row">
                    <div class="col-12 col-lg-5 col-xl-4 mx-auto">
                      <img [src]="('cdnBase' | env) + 'img/faq/change_service_1.png'" class="img-fluid img-thumbnail">
                    </div>
                  </div>
                  <br><br>
                  2. اختر الباقة المناسبة من القائمة المعروضة.
                  <br><br>
                  3. ستظهر لك رسالة للتأكيد، اختر "نعم" لتأكيد اختيارك للباقة.
                  <br><br>
                  <div class="row">
                    <div class="col-12 col-lg-5 col-xl-4 mx-auto">
                      <img [src]="('cdnBase' | env) + 'img/faq/change_service_2.png'" class="img-fluid img-thumbnail">
                    </div>
                  </div>
                  <br><br>                  
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>