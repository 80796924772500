import { Component, Input, OnInit } from '@angular/core';
import Swiper, { Autoplay} from 'swiper';


@Component({
  selector: 'app-swiper-slider',
  templateUrl: './swiper-slider.component.html',
  styleUrls: ['./swiper-slider.component.scss']
})
export class SwiperSliderComponent implements OnInit {

  @Input() props: {data: [], margin: number, mobileScreenstItems: number, tabletScreensItems: number, computerScreensItems: number};

  constructor() { }

  ngOnInit(): void {
    Swiper.use([Autoplay]);
  }

}
