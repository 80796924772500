<!-- <swiper class="rounded-lg h-100" [slidesPerView]="1" [autoplay]="{delay: 3000}" [speed]="400" [spaceBetween]="props['margin']"
    [pagination]="{ clickable: true }"> -->
<swiper *ngIf="props['data']" class="rounded-lg h-100" [slidesPerView]="1" [autoplay]="{delay: 2000, disableOnInteraction: false}" [speed]="600" [spaceBetween]="props['margin']" [pagination]="{ clickable: true }">    
    <ng-template swiperSlide *ngFor="let slide of props['data']">
        <div class="card rounded-lg h-100 shadow-sm">
            <div class="card-body p-0 h-100">
                <img class="rounded-lg h-100" [src]="slide" onerror="this.onerror=null;this.src='assets/img/on_error_slide.png'">
            </div>
        </div>
    </ng-template>
</swiper>