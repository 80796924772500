import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatBytesToGigaBytesPipe'
})
export class FormatBytesToGigaBytesPipe implements PipeTransform {

  transform(value, ...args: unknown[]): unknown {
      let digits = 2
      let result = ''
      if(value == 0) {
        return "0"+"|*|"+"GB"
      } else if(value > 0) {
        result = (value / (1024 * 1024 * 1024)).toFixed(digits);
      } else {
        result = "0"+"|*|"+"GB"
      }
    return result;
  }

}
