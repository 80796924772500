<div class="row mb-5">
    <div class="col-lg-12">
        <div class="card shadow-sm hover-shadow transition-fast rounded-lg mb-5">
            <div class="card-body p-5 px-0 px-lg-4 py-lg-4">
                <div class="container text-start">
                    <!-- <div class="row mb-3">
                        <div class="col-12">
                            <h4 class="font-weight-bold text-center text-lg-start">اختبار السرعة</h4>
                        </div>
                    </div> -->
                    <iframe width="100%" height="630px" frameborder="0" src="https://afq.speedtestcustom.com/"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
