import { AuthService } from './../../services/auth.service';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-mobile-sidebar',
  templateUrl: './mobile-sidebar.component.html',
  styleUrls: ['./mobile-sidebar.component.scss']
})
export class MobileSidebarComponent implements OnInit {

  @Input() userProfile = '';

  @ViewChild('offcanvasBody') offcanvasBody: ElementRef;
  @ViewChild('closeButton') closeButton: ElementRef;

  constructor(private authService: AuthService) { }

  isShown: boolean = false

  ngOnInit(): void {
  }

  show() {
    this.isShown = true
    document.addEventListener('click', this.checkIfClickedInside, true);      
  }

  hide(){
    this.closeButton.nativeElement.click()
    this.isShown = false
    document.removeEventListener("click", this.checkIfClickedInside, true);
  }

  logout() {
    this.hide()
    this.authService.operations.logout()
  }

  checkIfClickedInside = (event: Event) => {
    let isClickInside = this.offcanvasBody.nativeElement.contains(event.target);
    if (!isClickInside) {
      this.hide()
    }    
  }


}
