import { Component, Input, OnInit, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss']
})
export class VoucherComponent implements OnInit {

  @Input() speed: any;
  @Input() limited: any;
  @Input() price: any;
  @Input() quota: any;
  @Input() quotaUnit: any;
  @Input() filled: any;

  constructor() {}

  ngOnInit(): void {}

}

