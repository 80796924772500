import { AuthService } from 'src/app/services/auth.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { finalize, take } from 'rxjs/operators';
import { ActivateService } from 'src/app/services/resources/activate.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-renew-modal',
  templateUrl: './renew-modal.component.html',
  styleUrls: ['./renew-modal.component.scss']
})
export class RenewModalComponent implements OnInit {
  
  modalName = 'renewModal'
  isLoading: boolean = false
  isSending: boolean = false
  currentUUID: string = ''

  @Input() isActive;
  @Input() currentQuotaInformation;
  @Input() currentQuotaDescription;
  @Input() userBalance;

  @Output() isClosed = new EventEmitter<string>();
  @Output() refreshParent = new EventEmitter<boolean>();

  @ViewChild('modalContent') modalContent: ElementRef;
  @ViewChild('closeButton') closeButton: ElementRef;

  constructor(private activateService: ActivateService, private toastrService: ToastrService, private authService: AuthService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    if(changes['isActive']) {
      let change: SimpleChange = changes['isActive']
      // isFirstChange used to ignore first change event at the component initialization
      if(!change.isFirstChange() && change['currentValue']) {
        this.currentUUID = uuid.v4(); 
        document.addEventListener('click', this.checkIfClickedInside, true);      
      }
    }
  }

  activateSubscription(): void {
    this.isSending = true
    let data = {"uuid":this.currentUUID,"current_password":'true'}

    if(this.authService.checkIfCameFromAutoLogin()) {
      let userPassword = ''
      if(this.authService.userPassword) {
        userPassword = this.authService.userPassword
      } else {
        userPassword = prompt("الرجاء إدخال كلمة المرور لإتمام الإجراء")
      }
      if(userPassword) {
        data['current_password'] = userPassword
      } else {
        this.isSending = false
        return
      }
    }

    this.activateService.post(data)
    .pipe(take(1), finalize(() => this.isSending = false))
    .subscribe(res => {
      if(res['status'] == 200) {
        this.authService.userPassword = data['current_password']
        this.toastrService.success("لقد تم تجديد الإشتراك بنجاح!")
        this.closeModal()
        this.refreshDashboard()
        return        
      } else {
        if(res['status'] == -1) {
          if(res['message'] == 'rsp_insufficient_balance') {
            this.toastrService.error("عفوًا، ولكن رصيدك غير كافي لتفعيل الإشتراك!")
            return
          } 
          if(res['message'] == 'rsp_invalid_password') {
            this.toastrService.error("كلمة المرور التي ادخلتها غير صحيحة، الرجاء التحقق والمحاولة مجددًا!")
            return
          }           
        }
        this.toastrService.error("لقد حدث خطأ في تفعيل الخدمة، في حال تكرار المشكلة تواصل مع الدعم الفني!")
      }
    })
  }

  checkIfClickedInside = (event: Event) => {
    let isClickInside = this.modalContent.nativeElement.contains(event.target);
    if (!isClickInside) {
      this.closeModal()
    }    
  }

  closeModal(){
    this.closeButton.nativeElement.click()
    this.changeStatusToClosed(this.modalName)
    document.removeEventListener("click", this.checkIfClickedInside, true);
  }

  refreshDashboard() {
    this.refreshParent.emit(true)
  }

  changeStatusToClosed(modalName) {    
    this.isClosed.emit(modalName);
  }
}
