<div class="row mb-5">
    <div class="col-lg-12">
        <div class="card shadow-sm hover-shadow transition-fast rounded-lg mb-5">
            <div class="card-body p-5 px-0 px-lg-4 py-lg-4">
                <div class="container text-start">
                    <div class="row mb-3">
                        <div class="col-12">
                            <h4 class="font-weight-bold text-center text-lg-start">سجل الخدمة</h4>
                        </div>
                    </div>
                    <ng-container *ngIf="isLoading">
                        <div class="d-flex justify-content-center align-items-center my-5">
                            <div class="spinner-grow" style="width: 2rem; height: 2rem;" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!isLoading">
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover align-middle">
                                <thead class="bg-gray text-center">
                                    <tr>
                                        <th class="py-2" scope="col">رقم العملية</th>
                                        <th class="py-2" scope="col">التاريخ</th>
                                        <th class="py-2" scope="col">القيمة</th>
                                        <th class="py-2" scope="col">الوصف</th>
                                        <th class="py-2" scope="col">تاريخ الإستحقاق</th>
                                        <th class="py-2" scope="col">الحالة</th>
                                    </tr>
                                </thead>
                                <tbody class="text-center font-weight-normal">
                                    <tr *ngIf="!data.length">
                                        <td colspan="6">
                                             لا توجد بيانات لعرضها!
                                        </td>
                                    </tr>                                    
                                    <tr *ngFor="let item of data | paginate: {itemsPerPage:itemsPerPage, currentPage: page, totalItems: total}">
                                        <td class="py-3" scope="row">{{item?.invoice_number}}</td>
                                        <td class="py-3">{{item?.created_at}}</td>
                                        <td class="py-3">{{item?.amount}} د.ل</td>
                                        <td class="py-3">{{item?.description}}</td>
                                        <td class="py-3">{{item?.due_date}}</td>
                                        <td class="py-3" [ngClass]="item?.paid == 1 ? 'text-success' : 'text-warning'">{{item?.paid == 1 ? 'تم الدفع' : "غير مدفوع"}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="d-flex justify-content-center mt-2">
                            <pagination-controls (pageChange)="geteInvoices($event)" nextLabel="" previousLabel=""></pagination-controls>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>