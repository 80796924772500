import { SessionService } from './../../services/resources/session.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

@Component({
  selector: 'app-session-log',
  templateUrl: './session-log.component.html',
  styleUrls: ['./session-log.component.scss']
})
export class SessionLogComponent implements OnInit {

  isLoading: boolean = false
  data: any = {}
  page: number = 1;
  total: number = 0
  itemsPerPage: number = 7
  subscription: Subscription

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
    this.getSessions(1)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  getSessions(page: number){    
    this.isLoading = true
    let data = {"page":page,"count":this.itemsPerPage,"sortBy":"radacctid","direction":"desc"}
    this.subscription = this.sessionService.post(data)
    .pipe(take(1), finalize(() => this.isLoading = false))
    .subscribe(
      res => {
        this.page = page
        this.data = res['data']   
        this.total = res['total']     
      }
    )
  }

}
