import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit {

  isLoading: boolean = false
  data: any = {}
  parsedSupplliers: any = []
  subscription: Subscription

  selectedSupplier: any = ''

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.getSuppliers()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  get(route){    
    return this.http.get(route).pipe(map((response:any) => {
      return response;
    }));
  }

  getSuppliers(){
    this.isLoading = true
    this.subscription = this.get('./assets/json/suppliers.json')
    .pipe(take(1), finalize(() => this.isLoading = false))
    .subscribe(
      res => {
        this.data = res
        this.parsedSupplliers = []
        for(let supplier of this.data) {          
          if(supplier['city']) {
            let city = supplier['city']
            if(!this.parsedSupplliers[city]) {
              this.parsedSupplliers[city] = [];
            }            
            this.parsedSupplliers[city].push(supplier)
          }
        }        
        
        this.selectedSupplier = Object.keys(this.parsedSupplliers)[0]
        this.isLoading = false
      }, error => {        
        console.log(error);
      }
    )
  }
}
