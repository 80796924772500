<div class="card voucher-card shadow hover-shadow transition-fast rounded-lg h-100" [ngClass]="filled ? 'gradient-voucher  border-0' : 'border-dark'">
    <div class="card-body p-lg-4 d-grid" [ngClass]="filled ? 'text-white' : 'text-dark'">
        <div class="d-flex align-items-center h-100">
            <div class="position-relative h-100 w-100">
                <div style="position:absolute;top:0;right:0;">
                    <img [src]="'assets/img/voucher_logo_' + (filled ? 'white' : 'black') + '.png'" class="img-fluid quota-alofoq-logo" width="40" alt="الأفق الرقمي">
                </div>
                <div style="position:absolute;bottom:0;right:0;">
                    <h1 class="mb-0 quota-price" title="سعر الباقة" *ngIf="price">{{price}}<small style="font-size: large;">دل</small></h1>
                </div>
                <div class="card-content" style="position:absolute;bottom:0;left:0;">
                    <ng-container *ngIf="!quota">
                        <div class="w-100 mt-3 h-100 d-flex justify-content-center align-items-center">
                            <div class="spinner-grow" style="width: 2rem; height: 2rem;" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </ng-container> 
                    <ng-container *ngIf="quota">
                        <h1 dir="ltr" class="mb-0" [ngClass]="(limited | toBoolean) ? 'limited-speed' : 'unlimited-speed'">
                            <ng-container *ngIf="speed">
                                <div title="سرعة الباقة">
                                    <span class="font-weight-bold" [ngClass]="speed != 0 ? 'limited-speed' : 'unlimited-speed'">{{speed != 0 ? speed : 'سرعة غير محدودة'}}</span>
                                    <small style="font-size: medium;" *ngIf="speed != 0"> Mbps</small>
                                </div>
                            </ng-container>     
                            <div class="d-block text-end quota" title="سعة الباقة">  
                                <ng-container *ngIf="(limited | toBoolean) && quota && quotaUnit">
                                    <span class="font-weight-bold">{{quota}}</span>
                                    <small> {{quotaUnit}}</small>
                                </ng-container>
                                <ng-container *ngIf="!(limited | toBoolean)">
                                    <div>                                        
                                        <img [src]="'assets/img/unlimited_' + ((filled | toBoolean) ? 'white' : 'black') + '.png'" class="img-fluid unlimited" width="30" alt="Unlimited">
                                    </div>
                                </ng-container>
                            </div>
                        </h1>
                    </ng-container>  
                </div>
            </div>
        </div>
    </div>
</div>