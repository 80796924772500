import { Injectable } from '@angular/core';
import { CentralService } from '../central.service';

@Injectable({
  providedIn: 'root'
})

export class TrafficService extends CentralService {
  protected resource: string = "traffic";
}
