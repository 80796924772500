import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toBoolean'
})
export class ToBooleanPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let result = (value == 'true' || value == true) ? true : false
    return result;
  }

}
