<button class="btn btn-light p-0 outline-none" type="button" (click)="show()">
  <i class="fa fa-bars"></i>
</button>

<div #offcanvasBody class="offcanvas" [ngClass]="isShown ? 'visible off-canvas-shown' : ''" tabindex="-1" id="offcanvasRight">
  <div class="offcanvas-header justify-content-end">
    <button #closeButton type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" (click)="hide()"></button>
  </div>
  <div class="offcanvas-body">
    <div class="row px-4">
      <div class="col-md-12">
        <a routerLink="/profile" class="link-dark">
          <img src="assets/img/profile_picture.png" class="rounded-circle" alt="user image" width="50" height="50"
            title="اسم المستخدم" onerror="this.onerror=null;this.src='assets/img/on_error.jpg'">
        </a>
      </div>
      <div class="col-12">
      </div>
      <div class="col-md-12">
        <div class="d-flex h-100 align-items-center mt-1">
          <div class="w-100 mx-2">
            <h6 class="mb-0 font-weight-bold mb-0 w-auto" style="font-size: smaller">
              <a routerLink="/profile" class="link-dark">
                <small *ngIf="this.userProfile" title="اسم المشترك">
                  {{(this.userProfile['firstname'] || '') + ' ' + (this.userProfile['lastname'] || '')}}
                </small>
              </a>
            </h6>
            <ng-container *ngIf="this.userProfile">
              <a routerLink="/profile" class="link-dark">
                <small class="font-weight-normal">{{this.userProfile['username'] || ''}}</small>
              </a>
            </ng-container>
          </div>
        </div>
        <hr class="my-1">
      </div>
    </div>
    <ul id="mobileNavList" class="nav nav-pills flex-column my-3">
      <li class="nav-item my-2">
        <a class="btn text-reset rounded-3 w-100 text-start px-4" routerLink="/accounts" routerLinkActive="btn-light"
          [routerLinkActiveOptions]="{exact: true}" (click)="hide()">
          <div class="d-flex align-items-center">
            <span class="nav-item-icon material-icons me-2">payments</span>
            <h6 class="nav-item-text mb-0 d-inline"><small>الحسابات</small></h6>
          </div>
        </a>
      </li>
      <li class="nav-item my-2">
        <a class="btn  text-reset rounded-3 w-100 text-start px-4" routerLink="/session-log"
          routerLinkActive="btn-light" (click)="hide()">
          <div class="d-flex align-items-center">
            <span class="nav-item-icon material-icons me-2">sync_alt</span>
            <h6 class="nav-item-text mb-0 d-inline"><small>الجلسات</small></h6>
          </div>
        </a>
      </li>
      <li class="nav-item my-2">
        <a class="btn  text-reset rounded-3 w-100 text-start px-4" routerLink="/suppliers" routerLinkActive="btn-light" (click)="hide()">
          <div class="d-flex align-items-center">
            <span class="nav-item-icon material-icons me-2">storefront</span>
            <h6 class="nav-item-text mb-0 d-inline"><small>الموزعين</small></h6>
          </div>
        </a>
      </li>
      <li class="nav-item my-2">
        <a class="btn  text-reset rounded-3 w-100 text-start px-4" routerLink="/speed-test"
          routerLinkActive="btn-light" (click)="hide()">
          <div class="d-flex align-items-center">
            <span class="nav-item-icon material-icons me-2">speed</span>
            <h6 class="nav-item-text mb-0 d-inline"><small>السرعة</small></h6>
          </div>
        </a>
      </li>      
    </ul>
  </div>
  <div class="offcanvas-footer px-3">
    <div class="d-flex align-items-center text-alofoq">
      <span class="nav-link-icon material-icons">logout</span>
      <button class="btn btn-link text-alofoq text-decoration-none font-weight-bold" (click)="logout()">خروج</button>
    </div>
  </div>
</div>