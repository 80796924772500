import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from './../../services/resources/dashboard.service';
import { delay, finalize, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { QuotaService } from 'src/app/services/resources/quota.service';
import { RedeemService } from 'src/app/services/resources/redeem.service';
import { NgForm } from '@angular/forms';
import { UserService } from 'src/app/services/resources/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  // Modals Properties
  public showRenewModal = false
  public showChangePackageModal = false
  public showOffersModal = false
  public showRedeemModal = false

  autoRenewInput: boolean = false

  //  Loaders Properties
  isDashboardInformationLoading: boolean = false
  isQuotaInformationLoading: boolean = false
  isUserProfileLoading: boolean = false
  isVoucherRedeemLoading: boolean = false
  isAutoRenuewButtonLoading: boolean = false

  cdn = environment.cdnBase


  // Data
  homeSlides = [
    this.cdn + 'img/slider/slide_1.png',
    this.cdn + 'img/slider/slide_2.png',
    this.cdn + 'img/slider/slide_3.png',
    this.cdn + 'img/slider/slide_4.png',
  ]

  remainingPercentage: number
  quotaInformation: any = {limits:{rxtx_bytes:''}}
  currentQuotaDescription: any = {"city":"","speed":"","price":"","quota":"","quota_unit":"","type":"","Initial_Traffic":"","parent_type":""}
  dashboardInformation: any = {}
  userProfile: any = {
    "username": "","firstname": "","lastname": "","name": "","email": "","phone": "","address": "","company": "","registered_on": {
      "date": "","timezone_type": 0,"timezone": ""},"id": 0,"static_ip": null,"balance": 0,"auto_renew": 0,"profile_id": 0,"contract_id": "","loan_balance": 0}

  @ViewChild('openRenewModalButton') openRenewModalButton: ElementRef;

  constructor(private toastrService: ToastrService, private auth: AuthService, private userService: UserService, private quotaService: QuotaService, private dashboardService: DashboardService, private redeemService: RedeemService) { }

  ngOnInit(): void {
    this.refreshDashboard(false)
  }

  refreshDashboard(delay: boolean) {
    if(delay) {
      setTimeout(() => {
        this.getUserProfile()
        this.getQuotaInformation()
        this.getDashboardInformation()    
      }, 1000)
    } else {
      this.getUserProfile()
      this.getQuotaInformation()
      this.getDashboardInformation()         
    }
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe()
}

  getUserProfile() {
    this.isUserProfileLoading = true
    this.auth.getUserProfile()
      .pipe(take(1), finalize(() => this.isUserProfileLoading = false))
      .subscribe(
        res => {
          this.userProfile = res['data']
          this.autoRenewInput = res['data']['auto_renew'] == 1 ? true : false
        }
      )
  }

  getQuotaInformation() {
    this.isQuotaInformationLoading = true
    this.quotaService.getAll()
    .pipe(take(1), finalize(() => this.isQuotaInformationLoading = false))
    .subscribe(res => {
      this.quotaInformation = res['data']      
      try {
        this.currentQuotaDescription = JSON.parse(res['data']['description'])
      } catch(e) {
        this.toastrService.warning("وصف الباقة الحالية غير صحيح، الرجاء التواصل مع الدعم الفني لحل المشكلة!","خطأ في الباقة الحالية")
      }
      this.calculateRemainingPercentage()  
    })
  }

  getDashboardInformation() {
    this.isDashboardInformationLoading = true
    this.dashboardService.getAll()
    .pipe(take(1), finalize(() => this.isDashboardInformationLoading = false))
    .subscribe(res => {
      this.dashboardInformation = res['data']
    })
  }

  redeemVoucher(form: NgForm) {    
    if(form.valid) {
      this.isVoucherRedeemLoading = true;
      this.redeemService.post(form.value)
      .pipe(take(1), finalize(() => this.isVoucherRedeemLoading = false))
      .subscribe(
        res => {
          if(res['status'] == 200) {
            this.toastrService.success("لقد تم تعبئة الرصيد بنجاح!")
            this.refreshDashboard(true)
          } else {
            this.toastrService.error("رقم الكرت الذي قمت بإدخاله غير صحيح!")
          }
      })
    } else {
      this.toastrService.error("الرجاء التحقق من ادخال رقم الكرت بشكل صحيح!")
    }
  }

  calculateRemainingPercentage() {
    let usage = Number(this.quotaInformation['limits']['rxtx_bytes']);
    let total: number;

    // Check if type is 'new', use Initial_Traffic instead of traffic_limit
    if (this.currentQuotaDescription?.type === 'new') {
        total = Number(this.currentQuotaDescription['Initial_Traffic'] * 1024 * 1024);
    } else {
        total = Number(this.quotaInformation['traffic_limit']);
    }

    // Prevent division by zero
    this.remainingPercentage = total > 0 ? Math.round((usage / total) * 100) : 0;
}

  autoRenewChanged(status): void { 
    this.isAutoRenuewButtonLoading = true   
    let data = {"key":"auto_renew","value":status,"current_password":'true'}

    if(this.auth.checkIfCameFromAutoLogin()) {
      let userPassword = ''
      if(this.auth.userPassword) {
        userPassword = this.auth.userPassword
      } else {
        userPassword = prompt("الرجاء إدخال كلمة المرور لإتمام الإجراء")
      }
      if(userPassword) {
        data['current_password'] = userPassword
      }
    }

    this.userService.post(data)
    .pipe(take(1), finalize(() => this.isAutoRenuewButtonLoading = false))
    .subscribe(res => {      
      if(res['status'] == 200){
        this.autoRenewInput = status
        this.auth.userPassword = data['current_password']
      } else {
        if(res['message'] == 'rsp_invalid_password') {
          this.toastrService.error("كلمة المرور التي ادخلتها غير صحيحة، الرجاء التحقق والمحاولة مجددًا!")
          this.autoRenewInput = !status
          return
        }
        this.toastrService.error("لا يمكن تنفيذ العملية في حال تكرار المشكلة تواصل مع الدعم الفني!")
        this.autoRenewInput = !status
      }
    })
  }

  openRenewModal() {
    this.openRenewModalButton.nativeElement.click()
  }

  ModalClosedEvent(modalName) { 
    switch (modalName) {
      case 'renewModal':
        this.showRenewModal = false        
      break;
      case 'changePackageModal':
        this.showChangePackageModal = false
      break;
      case 'offersModal':
        this.showOffersModal = false
      break;
      case 'redeemModal':
        this.showRedeemModal = false
      break;
    }
  }

}
