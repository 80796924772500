<div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" [id]="modalName" tabindex="-1"
    aria-labelledby="changePackageModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered ">
        <div #modalContent class="modal-content glassmorphism-card rounded-lg px-2 px-lg-5 py-3">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title font-weight-bold" id="changePackageModalLabel">تغيير الباقة</h4>
                <button (click)="changeStatusToClosed(modalName)" #closeButton type="button" class="btn-close"
                    data-bs-dismiss="modal" aria-label="Close" title="إغلاق"></button>
            </div>
            <div class="modal-body pt-0">
                <ng-container *ngIf="step == 1">
                    <h5 class="decorated font-weight-bold mt-5 mt-lg-0"><span>الباقة الحالية</span></h5>
                    <app-loader-spinner [color]="'success'" *ngIf="isLoading"></app-loader-spinner>
                    <ng-container *ngIf="!isLoading">
                        <div class="row h-100 mt-3">
                            <div class="col-6 col-lg-5 col-xl-3 mx-auto">
                                <app-voucher [filled]="true" [price]="currentQuotaDescription?.price"
                                    [speed]="currentQuotaDescription?.speed" [quota]="currentQuotaDescription?.quota"
                                    [quotaUnit]="currentQuotaDescription?.quota_unit"
                                    [limited]="currentQuotaDescription?.type.toLowerCase() == 'unlimited' ? 'false' : 'true'">
                                </app-voucher>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!isLoading">

                        <ng-container *ngIf="parsedPackages['limited']">
                            <div class="mt-2">
                                <div class="ps-3">
                                    <h5 class="font-weight-bold border-bottom mt-5 mt-lg-0 pb-3 text-start"><span>باقات
                                            السرعة الفائقة</span>
                                    </h5>
                                </div>
                                <ng-container>
                                    <div class="row h-100 mt-3 gy-4">
                                        <ng-container *ngFor="let item of parsedPackages['limited']">
                                            <div
                                                class="col-6 col-lg-4 col-xl-3 my-3 scale-up-hover transition-fast cursor-pointer">
                                                <div (click)="selectedPackage = item;changeStep(1, 2)">
                                                    <app-voucher [filled]="false" [price]="item?.description.price"
                                                        [speed]="item?.description.speed" [quota]="item?.description.quota"
                                                        [quotaUnit]="item?.description.quota_unit"
                                                        [limited]="item?.description.type.toLowerCase() == 'unlimited' ? 'false' : 'true'">
                                                    </app-voucher>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="parsedPackages['unlimited']">
                            <div class="mt-3">
                                <div class="ps-3">
                                    <h5 class="font-weight-bold border-bottom mt-5 mt-lg-0 pb-3 text-start"><span>باقات
                                            التحميل اللامحدود</span></h5>
                                </div>
                                <ng-container>
                                    <div class="row h-100 mt-3">
                                        <ng-container *ngFor="let item of parsedPackages['unlimited']">
                                            <div
                                                class="col-6 col-lg-4 col-xl-3 my-3 scale-up-hover transition-fast cursor-pointer">
                                                <div (click)="selectedPackage = item;changeStep(1, 2)">
                                                    <app-voucher [filled]="false" [price]="item?.description.price"
                                                        [speed]="item?.description.speed" [quota]="item?.description.quota"
                                                        [quotaUnit]="item?.description.quota_unit"
                                                        [limited]="item?.description.type.toLowerCase() == 'unlimited' ? 'false' : 'true'">
                                                    </app-voucher>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>

                    </ng-container>
                </ng-container>

                <ng-container *ngIf="step == 2">
                    <app-loader-spinner [color]="'success'" *ngIf="isLoading"></app-loader-spinner>
                    <ng-container *ngIf="!isLoading">
                        <div class="row h-100 mt-4">
                            <div class="col-6 col-lg-5 col-xl-3 mx-auto">
                                <ng-container *ngIf="selectedPackage.description">
                                    <app-voucher [filled]="true" [price]="selectedPackage?.description?.price"
                                        [speed]="selectedPackage?.description?.speed" [quota]="selectedPackage?.description?.quota"
                                        [quotaUnit]="selectedPackage?.description?.quota_unit"
                                        [limited]="selectedPackage?.description?.type.toLowerCase() == 'unlimited' ? 'false' : 'true'">
                                    </app-voucher>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row mt-5">
                        <div class="col-12">
                            <h5 class="font-weight-bold text-center"><small>سعر الباقة الجديدة</small></h5>
                            <app-loader-spinner [color]="'success'" *ngIf="isLoading"></app-loader-spinner>
                            <ng-container *ngIf="!isLoading">
                                <h1 class="text-larger-1 mb-0" style="margin-top: -30px;">
                                    <span class="narrow-number">{{selectedPackage?.price}}</span>
                                    <span class="text-smaller ">دل</span>
                                </h1>
                            </ng-container>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <h5>هل انت متأكد من تغيير الباقة؟</h5>
                            <ng-container *ngIf="quotaInformation?.limits.rxtx_bytes >= 2097152 && ((quotaInformation?.subscription_status | contractStatus)?.status == 'منتهي الصلاحية')">
                                <div class="alert alert-danger py-2 shadow-sm hover-shadow transition-fast text-reset mt-4" style="border-radius: 10px;">
                                    متبقي لديك
                                    <span class="badge bg-danger rounded-pill mx-1" dir="ltr">
                                        {{quotaInformation?.limits?.rxtx_bytes | formatBytes | split: '|*|':0}} {{quotaInformation?.limits?.rxtx_bytes | formatBytes | split: '|*|':1}}
                                    </span>
                                   
                                    بحصتك الحالية، وستفقدها عند تغيير الباقة ولن تتمكن من استرجاعها! 
                                </div>
                            </ng-container>
                        </div>
                        <div class="col-12">
                            <button (click)="changePackage()" [disabled]="isSending"
                                class="btn btn-lg btn-success hover-shadow w-auto rounded-pill mx-auto pt-0 px-4 mt-2"
                                [disabled]="isLoading">
                                <ng-container *ngIf="isLoading || isSending">
                                    <div class="spinner-border spinner-border-sm" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </ng-container>
                                تأكيد تغيير الباقة
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>