import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable() 
export class HttpErrorInterceptor implements HttpInterceptor {

    errorMessage: any;
    isGetRequest: boolean;
    constructor(private router: Router,
                private toastrService: ToastrService,
                private authService: AuthService){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        if (this.authService.getJwtToken()) {
            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${this.authService.getJwtToken()}`
                }
            });            
        }
        
        return next.handle(request)
            .pipe(
                map((res: any) => {
                    if (res["status"] >= 200 && res["status"] <= 205) {
                        if(request["method"] == "GET") {return res}
                        
                        let excludeUrls = ['login','redeem','journal','session','invoice','user/extend', 'user/activate', 'service', 'traffic']
                        let isFound: Boolean = false

                        excludeUrls.forEach(element => {                            
                            if(request['url'].includes(element)) {
                                isFound = true
                            }
                        });

                        if(!isFound && res['body']['status'] != -1 && res['body']['status'] != -2) {
                            this.toastrService.success("تمت العملية بنجاح!")
                        }

                        return res;
                    }
                }),
                catchError((error: HttpErrorResponse) => {
                    request["method"] === "GET" ? this.isGetRequest = true: this.isGetRequest = false;
                    switch(error.status){
                        case 0:
                            this.errorMessage = "مشاكل شبكة";
                            break;
                        case 301:
                            this.errorMessage = "الصفحة التي طلبتها تم حذفها";
                            break;
                        case 400: 
                            this.errorMessage = error?.error?.message;
                            break;
                        case 401:
                            if(request['url'].includes('login')){
                                this.errorMessage = "تحقق من إدخال بيانات الدخول بشكل صحيح.";
                            } else {
                                this.errorMessage = "عليك تسجيل الدخول مرة أخرى";
                                this.router.navigate(['/login']);
                            }                         
                            break;
                        case 403: 
                            this.errorMessage = "هذه العملية غير متاحة لك";
                            break;
                        case 404:
                            this.errorMessage = "الصفحة التي تحاول والوصول إليها غير موجودة";
                            break;
                        case 405:
                            this.errorMessage = error?.error?.message;
                            break;
                        case 422:
                            this.errorMessage = error?.error?.message;
                            break;
                        case 429:
                            this.errorMessage = "لقد قمت بإدخال عمليات كثيرة لا يستطيع الخادم على إستيعابها، انتظر دقيقة واحدة.";
                            break;
                        case 500:
                            this.errorMessage = "لقد خدث خطأ من عند الخادم";
                            break;
                        default:
                            if (error.status >= 300 && error.status <= 399 ) {
                                this.errorMessage = "حدث خطأ عند التحويل";
                            } else if (error.status >= 400 && error.status <= 499 ) {
                                this.errorMessage = "حدث خطأ من جهة العميل";
                            } else if (error.status >= 500 && error.status <= 599 ) {
                                this.errorMessage = "حدث خطأ من جهة الخادم";
                            } else {
                                this.errorMessage = "حدث خطأ غير معروف";
                            }
                            break;
                    }
                    
                    if(error.status == 401 && !request['url'].includes('login')) {
                    } else {
                        this.toastrService.error(this.errorMessage, "حدث خطأ - (" + error.status + ')', {});
                    }

                    return throwError({errorStatus: error.status, errorMessage: this.errorMessage, isGetRequest: this.isGetRequest});
                }))
    }

    private addToken(request: HttpRequest<any>, token: string){
        return request.clone({
            setHeaders: {
                'Authorization':`Bearer ${token}` 
            }
        });
    }
}