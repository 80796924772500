import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {

  isLoading: boolean = false
  userProfile: any = {
    "username": "","firstname": "","lastname": "","name": "","email": "","phone": "","address": "","company": "","registered_on": {
      "date": "","timezone_type": 0,"timezone": ""},"id": 0,"static_ip": null,"balance": 0,"auto_renew": 0,"profile_id": 0,"contract_id": "","loan_balance": 0}

  constructor(private auth: AuthService) { }


  ngOnInit(): void {
    this.getUserProfile()
  }

  getUserProfile() {
    this.isLoading = true;
    this.auth.getUserProfile()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        res => {
          this.userProfile = res['data']
        }
      )
  }

  logout() {
    this.auth.operations.logout()
  }

}
