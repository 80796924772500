import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment.prod";
import { Observable } from "rxjs";
import { CryptoService } from './crypto.service';

@Injectable({
  providedIn: "root",
})
export class CentralService {
  protected resource: string = "";

  get fullURL(): string {    
    return (
      environment.baseApiURL + this.resource
    );
  }

  constructor(
    public http: HttpClient,
    private cryptoService: CryptoService
  ) {}

  getAll(params?: any): Observable<any> {
    return params
      ? this.http.get(this.fullURL, { params })
      : this.http.get(this.fullURL);
  }

  getSpecific(id: string | number, params?: any): Observable<any> {
    return this.http.get(this.fullURL + "/" + id, { params });
  }

  patch(id: string | number, data: any, params?: any): Observable<any> {
    data = this.cryptoService.encryptData(data)
    return this.http.patch(this.fullURL + "/" + id, data, { params });
  }

  put(id: string | number, data: any, params?: any): Observable<any> {
    data = this.cryptoService.encryptData(data)
    return this.http.put(this.fullURL + "/" + id, data, { params });
  }

  delete(id: string | number, params?: any): Observable<any> {
    return this.http.delete(this.fullURL + "/" + id, { params });
  }

  post(data: any, params?: any): Observable<any> {
    data = this.cryptoService.encryptData(data)
    return this.http.post(this.fullURL, data, { params });
  }

  postSpecific(id: string | number, data: any, params?: any): Observable<any> {
    data = this.cryptoService.encryptData(data)
    return this.http.post(this.fullURL + "/" + id, data, { params });
  }
}
