<ng-container *ngIf="multi[0]['series'] && isTolalEqualZero">
  <div class="d-grid" #areaChartContainer>
      <ngx-charts-area-chart
        [scheme]="colorScheme"
        [legend]="legend"
        [view]="view"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [yScaleMin]="yScaleMin"
        [legendTitle]="legendTitle"
        [legendPosition]="legendPosition"
        [autoScale]="autoScale"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [timeline]="timeline"
        [animations]="animations"
        [results]="multi"
        [curve]="linearCurve"
        (window:resize)="onResize($event)"
      >
      </ngx-charts-area-chart>
  </div>
</ng-container>