<div class="row mb-5">
    <div class="col-lg-12">
        <div class="card shadow-sm hover-shadow transition-fast rounded-lg mb-5">
            <div class="card-body p-5 px-0 px-lg-4 py-lg-4">
                <div class="container text-start">
                    <div class="row mb-3">
                        <div class="col-12">
                            <h4 class="font-weight-bold text-center text-lg-start">المساعدة</h4>
                        </div>
                    </div>
                    <div class="text-center">
                        <h2 class="font-weight-bold">كيف يمكننا مساعدتك؟</h2>
                        <div class="container">
                            <div class="row gy-5 mt-1 mb-5">
                                <div class="col-12 col-xl-7 mx-auto">
                                    <div class="row g-5">
                                        <div class="col-12 col-lg-6">
                                            <a routerLink="faq" class="link-dark-2">
                                                <div class="card clickable-card transition-fast rounded-lg">
                                                    <div class="card-body py-4 mt-3">
                                                        <span class="material-icons md-55">question_answer</span>
                                                        <h4 class="mt-2 font-weight-bold">الأسئلة المتكررة</h4>
                                                        <small class="text-white">سيتم تفعيل الخدمة قريبًا</small>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-12 col-lg-6">
                                            <a disabled class="link-dark-2">
                                                <div class="card clickable-card transition-fast rounded-lg">
                                                    <div class="card-body py-4 mt-3">
                                                        <span class="material-icons md-55">support_agent</span>
                                                        <h4 class="mt-2 font-weight-bold">الدعم الفني</h4>
                                                        <small class="text-danger">سيتم تفعيل الخدمة قريبًا</small>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>