import { Injectable } from '@angular/core';
import { CentralService } from '../central.service';

@Injectable({
  providedIn: 'root'
})

export class RedeemService extends CentralService {
  protected resource: string = "redeem";
}
