<div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" [id]="modalName" tabindex="-1" aria-labelledby="renewModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div #modalContent class="modal-content glassmorphism-card rounded-lg px-2 px-lg-5 py-3">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title font-weight-bold" id="renewModalLabel">تجديد الإشتراك</h4>
                <button (click)="changeStatusToClosed(modalName)" #closeButton type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" title="إغلاق"></button>
            </div>
            <div class="modal-body pt-0">                
                <h5 class="decorated font-weight-bold mt-5 mt-lg-0"><span>الباقة الحالية</span></h5>
                <app-loader-spinner [color]="'success'" *ngIf="isLoading"></app-loader-spinner>
                <ng-container *ngIf="!isLoading">
                    <div class="row h-100 mt-4">
                        <div class="col-6 col-lg-3 mx-auto">
                            <app-voucher [filled]="true" [price]="currentQuotaDescription?.price" [speed]="currentQuotaDescription?.speed" [quota]="currentQuotaDescription?.quota" [quotaUnit]="currentQuotaDescription?.quota_unit" [limited]="currentQuotaDescription?.type.toLowerCase() == 'unlimited' ? 'false' : 'true'"></app-voucher>
                        </div>
                    </div>
                </ng-container>
                <div class="row mt-5">
                    <div class="col-12">
                        <h5 class="font-weight-bold text-center"><small>سعر التجديد</small></h5>
                        <app-loader-spinner [color]="'success'" *ngIf="isLoading"></app-loader-spinner>
                        <ng-container *ngIf="!isLoading">
                            <h1 class="text-larger-1 mb-0" style="margin-top: -30px;">
                                <span class="narrow-number">{{currentQuotaDescription['price']}}</span>
                                <span class="text-smaller ">دل</span>
                            </h1>
                        </ng-container>
                    </div>
                </div>
                
                <ng-container *ngIf="userBalance >= currentQuotaInformation['price']">
                    <div class="row mt-4">
                        <h5>هل انت متأكد من التجديد؟</h5>
                        <button class="btn btn-success w-auto rounded-pill mx-auto pt-0 px-4 mt-2" (click)="activateSubscription()" [disabled]="isLoading || isSending">
                            <ng-container *ngIf="isLoading || isSending">
                                <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </ng-container>
                            تجديد الاشتراك
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="userBalance < currentQuotaInformation['price']">
                    <div class="row mt-4">
                        <div class="col-12">
                            <div class="alert alert-warning rounded-lg pt-2 pb-3">
                                <i class="fa fa-exclamation-triangle pe-1"></i>   
                                عفوًا، ولكن رصيدك غير كافي لتفعيل الإشتراك! 
                            </div>
                        </div>
                    </div>
                </ng-container>                
            </div>
        </div>
    </div>
</div>