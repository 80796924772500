import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  cypKey = environment.privateKey


  constructor() { }

  encryptData(data) {    
    const cypData = crypto.AES.encrypt(JSON.stringify(data), this.cypKey);
    let requestData = { payload: cypData.toString() };
    return requestData;
  }

  decryptData(data) {
    const bytes = crypto.AES.decrypt(data, this.cypKey);
    const originalText = bytes.toString(crypto.enc.Utf8);
    return originalText;
  }
}
