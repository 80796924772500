import { AuthService } from 'src/app/services/auth.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { finalize, take } from 'rxjs/operators';
import * as uuid from 'uuid';
import { RedeemService } from 'src/app/services/resources/redeem.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-redeem-modal',
  templateUrl: './redeem-modal.component.html',
  styleUrls: ['./redeem-modal.component.scss']
})
export class RedeemModalComponent implements OnInit {
  
  modalName = 'redeemModal'
  isLoading: boolean = false
  currentUUID: string = ''

  @Input() isActive;

  @Output() isClosed = new EventEmitter<string>();
  @Output() refreshParent = new EventEmitter<boolean>();

  @ViewChild('modalContent') modalContent: ElementRef;
  @ViewChild('closeButton') closeButton: ElementRef;

  constructor(private redeemService: RedeemService, private toastrService: ToastrService, private authService: AuthService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    if(changes['isActive']) {
      let change: SimpleChange = changes['isActive']
      // isFirstChange used to ignore first change event at the component initialization
      if(!change.isFirstChange() && change['currentValue']) {
        document.addEventListener('click', this.checkIfClickedInside, true);      
      }
    }
  }

  redeemVoucher(form: NgForm) {    
    if(form.valid) {
      this.isLoading = true;
      this.redeemService.post(form.value)
      .pipe(take(1), finalize(() => this.isLoading = false))
      .subscribe(
        res => {
          if(res['status'] == 200) {
            this.toastrService.success("لقد تم تعبئة الرصيد بنجاح!")
            this.closeModal()
            this.refreshDashboard()            
          } else {
            this.toastrService.error("رقم الكرت الذي قمت بإدخاله غير صحيح!")
          }
      })
    } else {
      this.toastrService.error("الرجاء التحقق من ادخال رقم الكرت بشكل صحيح!")
    }
  }

  checkIfClickedInside = (event: Event) => {
    let isClickInside = this.modalContent.nativeElement.contains(event.target);
    if (!isClickInside) {
      this.closeModal()
    }    
  }

  closeModal(){
    this.closeButton.nativeElement.click()
    this.changeStatusToClosed(this.modalName)
    document.removeEventListener("click", this.checkIfClickedInside, true);
  }

  refreshDashboard() {
    this.refreshParent.emit(true)
  }

  changeStatusToClosed(modalName) {    
    this.isClosed.emit(modalName);
  }
}
