import { TicketsComponent } from './screens/chat/tickets.component';
import { FormatBytesToGigaBytesPipe } from 'src/app/pipes/format-bytes-to-gigabytes.pipe';
import { RedeemModalComponent } from './components/modals/redeem-modal/redeem-modal.component';
import { ReplacePipe } from './pipes/replace.pipe';
import { ToBooleanPipe } from './pipes/toBoolean.pipe';
import { OwlSliderComponent } from './components/owl-slider/owl-slider.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HomeComponent } from './screens/home/home.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { LoaderGrowComponent } from './components/loaders/loader-grow/loader-grow.component';
import { LoaderSpinnerComponent } from './components/loaders/loader-spinner/loader-spinner.component';
import { RenewModalComponent } from './components/modals/renew-modal/renew-modal.component';
import { ChangePackageModalComponent } from './components/modals/change-package-modal/change-package-modal.component';
import { OffersModalComponent } from './components/modals/offers-modal/offers-modal.component';
import { UserProfileComponent } from './screens/user-profile/user-profile.component';
import { ServiceLogComponent } from './screens/service-log/service-log.component';
import { UsageLogComponent } from './screens/usage-log/usage-log.component';
import { SessionLogComponent } from './screens/session-log/session-log.component';
import { SpeedTestComponent } from './screens/speed-test/speed-test.component';
import { SuppliersComponent } from './screens/suppliers/suppliers.component';
import { AccountsComponent } from './screens/accounts/accounts.component';
import { FaqComponent } from './screens/faq/faq.component';
import { HelpComponent } from './screens/help/help.component';
import { PieComponent } from './components/charts/pie/pie.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AreaComponent } from './components/charts/area/area.component';
import { BottomNavComponent } from './components/bottom-nav/bottom-nav.component';
import { MobileSidebarComponent } from './components/mobile-sidebar/mobile-sidebar.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SwiperSliderComponent } from './components/swiper-slider/swiper-slider.component';
import { LoginComponent } from './screens/login/login.component';
import { VoucherComponent } from './components/voucher/voucher.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './services/interceptor.service';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { AutofocusDirective } from './directives/autofocus.directive';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CountUpModule } from 'ng-count-up-js';
import { FormatBytesPipe } from './pipes/format-bytes.pipe';
import { SplitPipe } from './pipes/split-pipe.pipe';
import { ContractStatusPipe } from './pipes/contractStatus.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { EnvPipe } from './pipes/env.pipe';

@NgModule({
  declarations: [
    AppComponent,
    AutofocusDirective,
    FormatBytesPipe,
    ContractStatusPipe,
    FormatBytesToGigaBytesPipe,
    SplitPipe,
    ToBooleanPipe,
    ReplacePipe,
    HomeComponent,
    OwlSliderComponent,
    MainLayoutComponent,
    SidebarComponent,
    TopNavComponent,
    RenewModalComponent,
    LoaderGrowComponent,
    LoaderSpinnerComponent,
    ChangePackageModalComponent,
    OffersModalComponent,
    RedeemModalComponent,
    UserProfileComponent,
    ServiceLogComponent,
    UsageLogComponent,
    SessionLogComponent,
    SpeedTestComponent,
    SuppliersComponent,
    AccountsComponent,
    FaqComponent,
    HelpComponent,
    PieComponent,
    AreaComponent,
    BottomNavComponent,
    MobileSidebarComponent,
    NotFoundComponent,
    SwiperSliderComponent,
    LoginComponent,
    VoucherComponent,
    TicketsComponent,
    EnvPipe,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    NgxPaginationModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    BrowserAnimationsModule,
    CarouselModule,
    SwiperModule,
    NgxSkeletonLoaderModule,
    NgxChartsModule,
    CountUpModule,
    ToastrModule.forRoot({progressBar: true, timeOut: 10000, positionClass: 'toast-top-center'}),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    FormatBytesPipe,
    FormatBytesToGigaBytesPipe,
    ContractStatusPipe,
    ToBooleanPipe,
    SplitPipe,
    ReplacePipe,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
