<div class="modal fade" [id]="modalName" tabindex="-1" aria-labelledby="redeemModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div #modalContent class="modal-content glassmorphism-card rounded-lg px-2 px-lg-5 py-3">
            <div class="modal-header border-bottom-0 pb-0">
                <h4 class="modal-title font-weight-bold" id="redeemModalLabel">تعبئة رصيد</h4>
                <button (click)="changeStatusToClosed(modalName)" #closeButton type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" title="إغلاق"></button>
            </div>
            <div class="modal-body pt-0">                
                <!-- <app-loader-spinner [color]="'success'" *ngIf="isLoading"></app-loader-spinner> -->
                <ng-container>
                    <div class="row h-100 mt-4">
                        <div class="col-12 col-lg-12 mx-auto">
                            <form #redeemForm="ngForm" (ngSubmit)="redeemVoucher(redeemForm)">
                                <div class="col-12">
                                    <div class="input-group p-0 input-group-rounded hover-shadow transition-fast rounded-lg h-100">
                                        <input type="text" class="form-control py-0" placeholder="للتعبئة ادخل رقم الكرت" [ngModel] #pin="ngModel" name="pin" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" [disabled]="isLoading" autofocus required>
                                        <div class="input-group-append">
                                            <button class="btn btn-sm btn-success btn-success-light px-4" type="submit" [disabled]="isLoading">
                                                <ng-container *ngIf="isLoading">
                                                    <div class="spinner-border spinner-border-sm" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="!isLoading">
                                                    تعبئة
                                                </ng-container>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>