import { NotFoundComponent } from './components/not-found/not-found.component';
import { FaqComponent } from './screens/faq/faq.component';
import { SpeedTestComponent } from './screens/speed-test/speed-test.component';
import { SessionLogComponent } from './screens/session-log/session-log.component';
import { ServiceLogComponent } from './screens/service-log/service-log.component';
import { UserProfileComponent } from './screens/user-profile/user-profile.component';
import { HomeComponent } from './screens/home/home.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsageLogComponent } from './screens/usage-log/usage-log.component';
import { SuppliersComponent } from './screens/suppliers/suppliers.component';
import { AccountsComponent } from './screens/accounts/accounts.component';
import { HelpComponent } from './screens/help/help.component';
import { LoginComponent } from './screens/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { TicketsComponent } from './screens/chat/tickets.component';


const MainLayoutChlidren: Routes = [
  {path: '', component: HomeComponent},
  {path: 'profile', component: UserProfileComponent},
  {path: 'service-log', component: ServiceLogComponent},
  {path: 'usage-log', component: UsageLogComponent},
  {path: 'session-log', component: SessionLogComponent},
  {path: 'speed-test', component: SpeedTestComponent},
  {path: 'suppliers', component: SuppliersComponent},
  {path: 'accounts', component: AccountsComponent},
  {path: 'help', component: HelpComponent},
  {path: 'help/faq', component: FaqComponent},
  //{path: 'help/tickets', component: TicketsComponent},
  //{path: 'help/tickets/:id', component: TicketsComponent},

  {path: '**', component: NotFoundComponent},
];

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: '', component: MainLayoutComponent, children: MainLayoutChlidren, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
