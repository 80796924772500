import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  isLoading : boolean = false
  userProfile: any = {firstname:'جاري التحميل ...',lastname:'',contract_id:'جاري التحميل ...',email:'جاري التحميل ...',phone:'جاري التحميل ...',address:'جاري التحميل ...',registered_on:{date:'جاري التحميل ...'}}
  subscription: Subscription
  
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.getUserProfile()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  getUserProfile() {
    this.isLoading = true
    this.subscription = this.authService.getUserProfile()
    .pipe(finalize(() => this.isLoading = false))
    .subscribe(res => {
      this.userProfile = res['data']      
    })
  }

}
