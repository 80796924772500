import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isLoading: boolean = false;
  public subscription: Subscription;

  user = {
    username: null,
    password: null,
    language: 'ar'
  };

  constructor(private router: Router, private toastrService: ToastrService, public auth: AuthService) { 
    this.autoLogin()
  }

  ngOnInit(): void {
    const locationAccessMessage = document.getElementById('location-access-message');
    if (locationAccessMessage) {
      locationAccessMessage.addEventListener('click', () => {
        // Request geolocation access here
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              // Handle successful geolocation access
            },
            (error) => {
              // Handle geolocation error
            }
          );
        } else {
          // Handle lack of geolocation support
        }
      });
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  autoLogin(): void {    
    if (sessionStorage.getItem('stop_auto_login') !== '1') {
      this.isLoading = true;
      this.subscription = this.auth.operations.autoLogin()
        .pipe(finalize(() => this.isLoading = false))
        .subscribe(
          (res) => {
            this.isLoading = false;
            if (res['token']) {
              localStorage.setItem('JWT_TOKEN', res['token']);
              this.router.navigateByUrl('/');
            } else if (res['status'] === -1 && res['message'] === 'unknown_user') {
              sessionStorage.setItem('stop_auto_login', '1');
            }
          });
    }
  }
  
  login(form): void {
    if (form.valid) {
      this.isLoading = true
      let requestData = {"username":form.value.username,"password":form.value.password,"language":"ar"};      
      this.subscription = this.auth.operations.login(requestData)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        (res) => {
          this.isLoading = false
          localStorage.setItem('JWT_TOKEN', res['token']);
          localStorage.removeItem('stop_auto_login');
          this.router.navigateByUrl('/');
        });
    }
    else {
      this.toastrService.error(
        'الرجاء التأكد من تعبئة البيانات بشكل صحيح','خطأ في البيانات')
    }
  }

}
