<div class="bottom-nav bg-light d-xl-none">
    <div class="container">
        <div class="row text-center">
            <div class="col">
                <a routerLink="/" class="d-block link-dark py-3" routerLinkActive="text-alofoq"
                    [routerLinkActiveOptions]="{exact: true}">
                    <span class="nav-link-icon material-icons">home</span>
                    <p class="nav-link-text mb-0">الرئيسية</p>
                </a>
            </div>
            <div class="col">
                <a routerLink="/service-log" class="d-block link-dark py-3" routerLinkActive="text-alofoq">
                    <span class="nav-link-icon material-icons">history</span>
                    <p class="nav-link-text mb-0"><small>السجل</small></p>
                </a>
            </div>
            <div class="col">
                <a routerLink="/usage-log" class="d-block link-dark py-3" routerLinkActive="text-alofoq">
                    <span class="nav-link-icon material-icons">bar_chart</span>
                    <p class="nav-link-text mb-0"><small>الإستخدام</small></p>
                </a>
            </div>
            <div class="col">
                <a routerLink="/help" class="d-block link-dark py-3" routerLinkActive="text-alofoq">
                    <span class="nav-link-icon material-icons">help_outline</span>
                    <p class="nav-link-text mb-0"><small>المساعدة</small></p>
                </a>
            </div>                        
        </div>
    </div>
</div>