import { AuthService } from 'src/app/services/auth.service';
import { OffersService } from './../../../services/resources/offers.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { ExtendService } from 'src/app/services/resources/extend.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-offers-modal',
  templateUrl: './offers-modal.component.html',
  styleUrls: ['./offers-modal.component.scss']
})
export class OffersModalComponent implements OnInit {

  modalName = 'offersModal'
  selectedOffer: any = {}
  isLoading: boolean = false;
  offers: any = []
  step: number = 1

  @Input() isActive;
  @Input() profileId;

  @Output() isClosed = new EventEmitter<string>();
  @Output() refreshParent = new EventEmitter<boolean>();

  @ViewChild('modalContent') modalContent: ElementRef;
  @ViewChild('closeButton') closeButton: ElementRef;

  constructor(private offersService: OffersService, private extendService: ExtendService, private toastrService: ToastrService, private authService: AuthService) {
    this.step = 1
   }

  ngOnInit(): void {}

  getOffers() {
    this.isLoading = true
    this.offersService.getSpecific(this.profileId)
    .pipe(take(1), finalize(() => this.isLoading = false))
    .subscribe(res => {
      this.offers = res['data']      
    })
  }

  changeStep(to) {
    this.step = to
  }
  
  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    if(changes['isActive']) {
      let change: SimpleChange = changes['isActive']
      // isFirstChange used to ignore first change event at the component initialization
      if(!change.isFirstChange() && change['currentValue']) { 
        document.addEventListener('click', this.checkIfClickedInside, true);
        this.getOffers()
      }
    }
  }
  
  checkIfClickedInside = (event: Event) => {
    let isClickInside = this.modalContent.nativeElement.contains(event.target);    
    if (!isClickInside) {
      this.closeModal()
    }    
  }

  activateOffer(): void {
    this.isLoading = true
    let data = {"profile_id":this.selectedOffer.id,"current_password":'true'}

    if(this.authService.checkIfCameFromAutoLogin()) {
      let userPassword = ''
      if(this.authService.userPassword) {
        userPassword = this.authService.userPassword
      } else {
        userPassword = prompt("الرجاء إدخال كلمة المرور لإتمام الإجراء")
      }
      if(userPassword) {
        data['current_password'] = userPassword
      } else {
        this.isLoading = false
        return
      }
    }

    this.extendService.post(data)
    .pipe(take(1), finalize(() => this.isLoading = false))
    .subscribe(res => {

      if(res['status'] == 200) {
        this.authService.userPassword = data['current_password']
        this.toastrService.success("تم تفعيل العرض بنجاح!")
        this.closeModal()
        this.refreshDashboard()
        return     
      }
      if(res['status'] == -1) {
        if(res['message'] == 'rsp_insufficient_balance') {
          this.toastrService.error("لا يمكنك تفعيل العرض، رصيدك غير كافي للحصول على هذا العرض!")
          return
        }
        if(res['message'] == 'rsp_invalid_password') {
          this.toastrService.error("كلمة المرور التي ادخلتها غير صحيحة، الرجاء التحقق والمحاولة مجددًا!")
          return
        }        
      }      
      this.toastrService.error("لقد حدث خطأ في تفعيل الخدمة، في حال تكرار المشكلة تواصل مع الدعم الفني.!")

    })
  }

  closeModal(){    
    this.closeButton.nativeElement.click()
    this.changeStatusToClosed(this.modalName)
    document.removeEventListener("click", this.checkIfClickedInside, true);
  }

  refreshDashboard() {
    this.refreshParent.emit(true)
  }

  changeStatusToClosed(modalName) {
    this.isClosed.emit(modalName);
    setTimeout(() => {  this.step = 1; }, 1000);
    this.selectedOffer = {}
  }

}
