import { AuthService } from 'src/app/services/auth.service';
import { QuotaService } from 'src/app/services/resources/quota.service';
import { PackagesService } from './../../../services/resources/packages';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-package-modal',
  templateUrl: './change-package-modal.component.html',
  styleUrls: ['./change-package-modal.component.scss']
})
export class ChangePackageModalComponent implements OnInit {

  modalName = 'changePackageModal'
  selectedPackage: any = {}
  isLoading: boolean = false
  isSending: boolean = false
  packages: any = {}
  parsedPackages: any = []
  step: number = 1

  @Input() isActive;
  @Input() currentQuotaDescription;
  @Input() quotaInformation;


  @Output() isClosed = new EventEmitter<string>();
  @Output() refreshParent = new EventEmitter<boolean>();
  @Output() openRenuewModal = new EventEmitter<boolean>();

  @ViewChild('modalContent') modalContent: ElementRef;
  @ViewChild('closeButton') closeButton: ElementRef;

  constructor(private packagesService: PackagesService, private quotaService: QuotaService, private toastrService: ToastrService, private authService: AuthService) {
    this.step = 1
  }

  ngOnInit(): void {
  }


  changeStep(from, to) {
    this.step = to
  }

  getPackages() {
    this.isLoading = true
    this.packagesService.getAll()
    .pipe(take(1), finalize(() => this.isLoading = false))
    .subscribe(res => {
      this.packages = res['data']
      this.packagesFiltration();
    })
  }

  packagesFiltration() {    
    this.parsedPackages = {}
    let sortedPackages = this.packages.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));

    for(let p of sortedPackages) {
      if(p['description']) {
        p['description'] = JSON.parse(p['description'])
        let type = p['description']['type'].toLowerCase()
        if(!this.parsedPackages[type]) {
          this.parsedPackages[type] = [];
        }
  
        // Check if not equal current package (to hide current package from the list)
        if(JSON.stringify(p['description']) != JSON.stringify(this.currentQuotaDescription)) {
          this.parsedPackages[type].push(p)
        }
      }
    }
  }

  changePackage(): void {
    this.isSending = true
    let data = {"new_service":this.selectedPackage.id,"current_password":'true'}

    if(this.authService.checkIfCameFromAutoLogin()) {
      let userPassword = ''
      if(this.authService.userPassword) {
        userPassword = this.authService.userPassword
      } else {
        userPassword = prompt("الرجاء إدخال كلمة المرور لإتمام الإجراء")
      }
      if(userPassword) {
        data['current_password'] = userPassword
      } else {
        this.isSending = false
        return
      }
    }

    this.quotaService.post(data)
    .pipe(take(1), finalize(() => this.isSending = false))
    .subscribe(res => {
      if(res == null) {
        this.toastrService.warning("يجب عليك اختيار باقة مختلفة عن باقتك الحالية!")
        this.step = 1
        this.selectedPackage = {}
        return 
      }
      if(res['status']) {
        if(res['status'] == 200) {
          this.authService.userPassword = data['current_password']
          this.toastrService.success("لقد تم تغيير الباقة بنجاح!")
          this.closeModal()
          this.refreshDashboard()
          this.openRenuewModal.emit(true)
          return
        }
      }
      if(res['message']) {
        if(res['message'] == "rsp_service_change_user_active") {
          this.toastrService.warning("إشتراكك مفعل حاليًا، تواصل مع الدعم الفني في حال رغبتك في إيقاف الإشتراك الحالي!")
          return
        } 
        else if(res['message'] == 'rsp_invalid_password') {
          this.toastrService.error("كلمة المرور التي ادخلتها غير صحيحة، الرجاء التحقق والمحاولة مجددًا!")
          return
        }
        else {
          this.toastrService.error("لقد حدث خطأ في تفعيل الخدمة، في حال تكرار المشكلة تواصل مع الدعم الفني.!")
          return
        }
      }
    })
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    if(changes['isActive']) {   
      let change: SimpleChange = changes['isActive']
      // isFirstChange used to ignore first change event at the component initialization
      if(!change.isFirstChange() && change['currentValue']) { 
        document.addEventListener('click', this.checkIfClickedInside, true);
        this.getPackages()
      }
    }

  }

  checkIfClickedInside = (event: Event) => {
    let isClickInside = this.modalContent.nativeElement.contains(event.target);
    if (!isClickInside) {
      this.closeModal()
    }    
  }

  closeModal(){
    this.closeButton.nativeElement.click()
    this.changeStatusToClosed(this.modalName)
    document.removeEventListener("click", this.checkIfClickedInside, true);
  }

  refreshDashboard() {
    this.refreshParent.emit(true)
  }

  changeStatusToClosed(modalName) {
    this.isClosed.emit(modalName);
    setTimeout(() => {  this.step = 1; }, 1000);
    this.selectedPackage = {}   
  }

}
