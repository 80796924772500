import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import * as crypto from 'crypto-js';
import { Observable, from } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  api: string = environment.baseApiURL
  private JWT_TOKEN = 'JWT_TOKEN'
  cypKey = environment.privateKey
  userPassword: string = ''

  constructor(public http: HttpClient, public router: Router) { }

  operations = {
    autoLogin: () => {
      return new Observable(observer => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const longitude = position.coords.longitude;
              const latitude = position.coords.latitude;
    
              console.log(`Longitude: ${longitude}`);
              console.log(`Latitude: ${latitude}`);
    
              this.http.get(`${this.api}auth/autoLogin?lat=${latitude}&long=${longitude}`)
                .pipe(
                  catchError(error => {
                    observer.error(error);
                    return [];
                  }),
                  finalize(() => {
                    observer.complete();
                  })
                )
                .subscribe(
                  (res) => {
                    observer.next(res);
                  }
                );
            },
            (error) => {
              console.log("Geolocation error:", error);
              observer.error("Geolocation error");
            }
          );
        } else {
          console.log("No support for geolocation");
          observer.error("No support for geolocation");
        }
      });
    },
    login: (body) => {
      const cypData = crypto.AES.encrypt(JSON.stringify(body), this.cypKey)
      let requestData = {payload: cypData.toString()}
      return this.http.post(this.api + 'auth/login', requestData)
    },
    logout: () => {
      localStorage.removeItem(this.JWT_TOKEN)
      sessionStorage.setItem('stop_auto_login', '1');
      this.router.navigateByUrl('/login')
    }
  }

  isloggedIn() {
    return !!this.getJwtToken();
  }

  getUserProfile() {
    return this.http.get(this.api + 'user')
  }

  checkIfCameFromAutoLogin() {
    if(localStorage.getItem(this.JWT_TOKEN)) {
      let parsedToken = JSON.parse(atob(localStorage.getItem(this.JWT_TOKEN).split('.')[1]))
      if(parsedToken['iss'].includes("autoLogin")) {
        return true
      }
    }
    return false
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN)
  }
}
