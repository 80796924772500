<ng-container *ngIf="dashboardInformation?.unpaid_invoices || dashboardInformation?.loan?.days != null || dashboardInformation?.loan?.rxtx_mb != null">
    <div class="row">
        <div class="col-12">
            <div class="card shadow-sm hover-shadow transition-fast" style="border-radius: 10px;background: #ffe5e5;">
                <div class="card-body py-2 font-weight-bold text-start" style="font-weight: 500;">
                    <div class="row">
                        <ng-container *ngIf="dashboardInformation?.loan?.days != null || dashboardInformation?.loan?.rxtx_mb != null">
                            <div class="col-12">
                                <small class="d-block my-1">
                                    <i class="fa fa fa-exclamation-triangle pe-1 text-danger"></i>
                                    لديك سلفة بقيمة 
                                    <span class="text-danger font-weight-bold" dir="ltr">
                                        {{(dashboardInformation?.loan?.rxtx_mb * 1048576) | formatBytes | split: '|*|':0 }}{{(dashboardInformation?.loan?.rxtx_mb * 1048576) | formatBytes | split: '|*|':1 }}
                                    </span>،
                                    <span class="text-danger font-weight-bold">
                                      {{dashboardInformation?.loan?.days}} أيام
                                  </span> 
                                  سيتم خصمها من حسابك عند تجديد الإشتراك.
                                </small>
                            </div>
                        </ng-container>                        
                        <ng-container *ngIf="dashboardInformation['unpaid_invoices']">
                            <div class="col-12">
                                <small class="d-block my-1">
                                    <div class="row">
                                        <div class="col-12 col-md-9">
                                            <div class="mt-1">                                                
                                                <i class="fa fa fa-exclamation-triangle pe-1 text-danger"></i>
                                                لديك عدد
                                                <span class="text-danger font-weight-bold" dir="ltr">{{dashboardInformation['unpaid_invoices']}}</span> فواتير مستحقة بحاجة إلى الدفع 
                                            </div>
                                        </div>
                                        <div class="col-12 mt-2 mt-md-0 col-md-3 text-md-end text-start">
                                            <a class="link-dark float-xl-end btn mb-1 btn-light btn-sm px-3 rounded-lg pt-0 shadow-sm hover-shadow" routerLink="service-log">معرفة المزيد</a>
                                        </div>
                                    </div>
                                </small>
                            </div>
                        </ng-container> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>



<div class="row  mt-4 mb-5 pb-5 mb-lg-0 pb-lg-0">
    <!-- Main Slider -->
    <div class="col-12 col-sm-12 col-lg-8 mb-4 custom-order-1 custom-order-sm-1 custom-order-lg-1">
                <div id="homeSlider" class="h-100">
                    <app-swiper-slider [props]="{data: homeSlides, margin: 40, mobileScreenstItems: 1, tabletScreensItems: 1, computerScreensItems: 1}"></app-swiper-slider>
                </div>
                <!-- <app-owl-slider [props]="{data: homeSlides, margin: 50, mobileScreenstItems: 1, tabletScreensItems: 1, computerScreensItems: 1}"></app-owl-slider> -->
                <!-- <img src="assets/img/fakeSlide.png" onerror="this.onerror=null;this.src='assets/img/on_error_slide.png'" alt="Slide Title" title="Slide Title"> -->
    </div>
    <!-- الرصيد -->
    <div class="col-6 col-sm-4 col-lg-4 mb-4 custom-order-3 custom-order-sm-3 custom-order-lg-2">
        <div class="card shadow-sm hover-shadow transition-fast rounded-lg h-100">
            <div class="card-body px-md-3 py-3 py-md-4 px-xl-5 pt-xl-3">
                <div class="row">
                    <div class="col-12 px-0 text-xl-start text-center">
                        <h4 class="font-weight-bold">الرصيــد</h4>
                    </div>                      
                    <div class="col-12 col-lg text-xl-end px-0">
                        <h1 class="balance-large-screen text-larger mb-0 text-center"><span class="narrow-number">
                            <ng-container *ngIf="userProfile['balance']">
                                <ng-container *ngIf="userProfile['balance'].toString().includes('.')">{{userProfile['balance']}}</ng-container>
                                <ng-container *ngIf="!userProfile['balance'].toString().includes('.')">
                                    <span appCountUp
                                        [endVal]="userProfile['balance']"
                                        [duration]='5'
                                        [useGrouping]="true">
                                    </span>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!userProfile['balance']">
                                0
                            </ng-container>                            
                        </span><span class="text-small font-weight-normal">دل</span></h1>
                    </div>
                    <div class="col-12 d-xl-none">
                        <button
                            class="btn btn-sm btn-success gradient-balance rounded-pill pt-0 pb-1 mt-3 px-4"
                            data-bs-toggle="modal"
                            data-bs-target="#redeemModal"
                            (click)="showRedeemModal = true"
                        >تعبئة الرصيد</button>
                    </div>
                </div>
                <div class="row mt-5 pt-3 mt-lg-2 d-none d-xl-flex">
                    <form #redeemForm="ngForm" (ngSubmit)="redeemVoucher(redeemForm)" class="px-0">
                        <div class="col-12 px-0">
                            <div class="input-group p-0 input-group-rounded hover-shadow transition-fast rounded-1 h-100">
                                <input type="text" class="form-control py-0" placeholder="للتعبئة ادخل رقم الكرت" [ngModel] #pin="ngModel" name="pin" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" required>
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-success btn-success-light px-4 pt-1 pb-2" type="submit" [disabled]="isVoucherRedeemLoading">
                                        <ng-container *ngIf="isVoucherRedeemLoading">
                                            <div class="spinner-border spinner-border-sm" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="!isVoucherRedeemLoading">
                                            تعبئة
                                        </ng-container>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- الحصة المتبقية -->
    <div class="col-6 col-sm-4 col-lg-3 mb-4 custom-order-2 custom-order-sm-2 custom-order-lg-3">
        <div class="card shadow-sm hover-shadow transition-fast rounded-lg h-100 border-0 gradient-balance">
            <div class="card-body text-white px-0">
                <div class="d-flex align-items-center justify-content-center h-100">
                    <div class="row w-100">
                        <div class="col-12 text-center">
                            <h6 class="font-weight-bold">الحصة المتبقية</h6>
                        </div>                    
                        <div class="col-12 text-center">
                            <ng-container *ngIf="isDashboardInformationLoading">
                                <div class="w-100 mt-4 d-flex justify-content-center align-items-center">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </ng-container>
                            <h1 class="mb-0 balance-text" dir="ltr" *ngIf="!isDashboardInformationLoading">
                                <ng-container *ngIf="dashboardInformation?.remaining_traffic == '_'">
                                    <span class="material-icons" style="font-size: 1.3em;">all_inclusive</span>
                                    <small class="d-block unlimited-remaining-traffic-text">غير محدود</small>
                                </ng-container>
                                <!-- <div>{{quotaInformation?.limits?.rxtx_bytes | formatBytes | split: '|*|':0}}</div> -->
                                <ng-container *ngIf="dashboardInformation?.remaining_traffic != '_'">
                                    <span class="narrow-number">
                                        <span>
                                            {{dashboardInformation?.remaining_traffic | formatBytes | split: '|*|':0}}
                                        </span>

                                        <!-- <span appCountUp
                                            [endVal]="quotaInformation?.limits?.rxtx_bytes | formatBytes | split: '|*|':0"
                                            [duration]="5"
                                            [useGrouping]="true"
                                        >
                                        </span> -->
                                    </span>
                                    <small class="text-smaller pt-5 pe-1 pe-xl-0">
                                        {{dashboardInformation?.remaining_traffic | formatBytes | split: "|*|":1}}
                                    </small>
                                </ng-container>                            
                            </h1>
                            <div class="col-12">
                                <button
                                    class="btn btn-sm mt-4 px-lg-4 pt-0 pb-1 btn-outline-light hover-shadow rounded-pill"
                                    data-bs-toggle="modal"
                                    data-bs-target="#renewModal"
                                    #openRenewModalButton
                                    (click)="showRenewModal = true"
                                >تجديد الاشتراك</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- الأيام المتبقية/الباقة الحالية -->
    <div class="col-12 col-sm-8 col-lg-6 mb-4 custom-order-4 custom-order-sm-6 custom-order-lg-4">
        <div class="card shadow-sm hover-shadow transition-fast rounded-lg h-100">
            <div class="card-body px-lg-3 py-lg-4">
                <div class="row h-100">
                    <div class="col-6 col-lg-7 ps-lg-4">
                        <ng-container *ngIf="currentQuotaDescription">
                            <app-voucher [filled]="true" [price]="currentQuotaDescription?.price" [speed]="currentQuotaDescription?.speed" [quota]="currentQuotaDescription?.quota" [quotaUnit]="currentQuotaDescription?.quota_unit" [limited]="currentQuotaDescription?.type.toLowerCase() == 'unlimited' ? false : true"></app-voucher>
                        </ng-container>
                    </div>
                    <div class="col-6 col-lg-5 text-center">
                        <div class="d-flex align-items-center justify-content-center h-100 border-start ps-2">
                            <div class="row">
                                <div class="col-12">
                                    <h6 class="font-weight-bold"><small>الأيام المتبقية</small></h6>
                                </div>
                                <div class="col-12">
                                    <ng-container *ngIf="isDashboardInformationLoading && isQuotaInformationLoading">
                                        <div class="spinner-grow mt-3" style="width: 2rem; height: 2rem;" role="status">
                                            <span class="sr-only">Loading...</span>
                                          </div>
                                    </ng-container>
                                    
                                    <ng-container *ngIf="!isDashboardInformationLoading && !isQuotaInformationLoading">
                                        <h1 class="mb-0 remaining-days">
                                            <span class="narrow-number">
                                                <span appCountUp
                                                    [endVal]="dashboardInformation['remaining_days']"
                                                    [duration]="3"
                                                    [useGrouping]="true"
                                                ></span>
                                            </span>
                                        </h1>
                                        <div class="font-weight-normal" title="تاريخ الإنتهاء">
                                            <p class="mb-0"><small>{{quotaInformation['expiration'] | date:'yyyy/MM/dd'}}</small></p>
                                            <hr class="w-50 mx-auto py-0 my-0">
                                            <p class="mb-0"><small class="d-block">{{quotaInformation['expiration'] | date:'shortTime'}}</small></p>
                                        </div>
                                    </ng-container>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-6 col-sm-4 col-lg-3 mb-4 custom-order-5 custom-order-sm-5 custom-order-lg-5">
        <div class="card shadow-sm hover-shadow transition-fast rounded-lg h-100">
            <div class="card-body">
                <div class="d-flex h-100 align-items-center justify-content-center">
                    <div>
                        <ng-container *ngIf="!dashboardInformation">
                            <div class="w-100 h-100 d-flex justify-content-center align-items-center">
                                <div class="spinner-grow" style="width: 2rem; height: 2rem;" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="dashboardInformation">
                            <div class="row">
                                <div class="col-12">
                                    <ng-container *ngIf="dashboardInformation?.remaining_traffic != '_' || remainingPercentage == 0">
                                        
                                        <h6 class="mb-0 font-weight-bold"><small>الإستهلاك المتبقي</small></h6>
                                        <app-pie [percentage]="remainingPercentage"></app-pie>
                                    </ng-container>
                                    <ng-container *ngIf="dashboardInformation?.remaining_traffic == '_'">
                                        <ng-container *ngIf="dashboardInformation?.remaining_traffic == '_'">
                                            <div class="col-12 text-center">
                                                <h6 class="mb-0 font-weight-bold"><small>الإستهلاك المتبقي</small></h6>
                                            </div>
                                            <h1 class="text-larger mb-0 text-success-light pt-2 pb-3" dir="ltr">
                                                <span class="material-icons" style="font-size: 1em;">all_inclusive</span>
                                                <small class="d-block unlimited-remaining-traffic-text">غير محدود</small>
                                            </h1>
                                        </ng-container>
                                    </ng-container>
                                    
                                    
                                </div>
                                <!-- <div class="col-12 d-none d-xl-block">
                                    <button
                                        class="btn btn-sm px-2 pt-0 pb-1 btn-outline-success hover-shadow rounded-pill font-weight-bold"
                                        data-bs-toggle="modal"
                                        data-bs-target="#renewModal"
                                        (click)="showRenewModal = true"
                                    >تجديد الاشتراك</button>
                                </div> -->
                                <div class="col-12 d-xl-none">
                                    <div class="form-check form-switch">
                                        <label>
                                            <small>
                                                تجديد تلقائي
                                            </small>
                                            <input class="form-check-input mt-2" [disabled]="isAutoRenuewButtonLoading" [ngClass]="autoRenewInput == false ?'border-secondary bg-secondary' : 'border-success-light bg-success-light'" type="checkbox" id="autoRenew" (change)="autoRenewChanged($event.target.checked)" [attr.checked]="autoRenewInput ? 'checked' : null">
                                        </label>
                                    </div>
                                </div>                        
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- العروض المتوفرة -->
    <div class="col-6 col-sm-6 col-lg-3 mb-4 custom-order-7 custom-order-sm-8 custom-order-lg-6">
        <div class="card shadow-sm hover-shadow transition-fast rounded-lg h-100">
            <div class="card-body text-white p-0 px-lg-4 position-relative">
                <div class="d-flex align-items-center h-100">
                    <button 
                    type="button"
                    class="btn btn-link outline-none p-0"
                    (click)="showOffersModal = true"
                    data-bs-toggle="modal"
                    data-bs-target="#offersModal"
                >
                    <img src="assets/img/offers_button.png" class="img-fluid rounded-pill" alt="العروض المتوفرة" title="العروض المتوفرة">
                </button>
                </div>  
            </div>
        </div>
    </div>
    <!-- حالة الحساب -->
    <div class="col-6 col-sm-6 col-lg-3 mb-4 custom-order-6 custom-order-sm-9 custom-order-lg-7">
        <div class="card shadow-sm hover-shadow transition-fast rounded-lg h-100">
            <div class="card-body">
                <div class="d-inline-flex h-100 align-items-center">
                    <div class="row align-self-stretch text-center">
                        <div class="col-12">
                            <h6 class="font-weight-bold">حالة الحساب</h6>
                        </div>
                        <div class="col-12 mt-3">
                            <ng-container *ngIf="!quotaInformation.subscription_status">
                                <div class="spinner-grow" style="width: 1rem; height: 1rem;" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                            </ng-container>
                            <div class="row text-center" style="margin-top: -7px;" *ngIf="quotaInformation.subscription_status">
                                <div class="col-6 pe-0">
                                    <span class="material-icons" [class]="'text-' + (quotaInformation?.subscription_status | contractStatus)?.bootstrapClass">
                                        {{(quotaInformation?.subscription_status | contractStatus)?.status == 'فعال' ? 'circle' : 'error'}}
                                    </span>
                                    <h6 style="margin-top: -5px;"><small class="text-sm-smaller">{{(quotaInformation?.subscription_status | contractStatus)?.status}}</small></h6>
                                </div>
                                <div class="col-6 ps-0">
                                    <span class="material-icons" [ngClass]="quotaInformation?.status ? 'text-success-light' : 'text-black-50'">{{quotaInformation?.status ? 'wifi' : 'wifi_off'}}</span>
    
                                    <h6 style="margin-top: -5px;"><small class="text-sm-smaller">{{quotaInformation?.status ? 'متصل' : 'غير متصل'}}</small></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- إعدادات التجديد -->
    <div class="col-6 col-sm-4 col-lg-3 mb-4 d-none d-lg-block custom-order-sm-9 custom-order-8 custom-order-lg-8">
        <div class="card shadow-sm hover-shadow transition-fast rounded-lg h-100">
            <div class="card-body">
                <div class="d-inline-flex h-100 ">
                    <div class="row text-center align-self-stretch">
                        <div class="col-12">
                            <h6 class="font-weight-bold mt-1">إعدادات التجديد</h6>
                        </div>
                        <div class="col-12">
                            <div class="form-check form-switch">
                                <label>
                                    تجديد تلقائي
                                    <input class="form-check-input mt-2" [disabled]="isAutoRenuewButtonLoading" [title]="isAutoRenuewButtonLoading ? 'جاري التحميل ...' : ''" [ngClass]="autoRenewInput == false ?'border-secondary bg-secondary' : 'border-success-light bg-success-light'" type="checkbox" id="autoRenew" (click)="autoRenewChanged($event.target.checked)" [(ngModel)]="autoRenewInput"
                                    [ngModelOptions]="{standalone: true}" [attr.checked]="autoRenewInput ? 'checked' : null">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- الباقات -->
    <div class="col-6 col-sm-4 col-lg-3 mb-4 custom-order-9 custom-order-sm-7 custom-order-lg-9">
        <div class="card shadow-sm hover-shadow transition-fast rounded-lg h-100">
            <div class="card-body">
                <div class="d-grid flex-column align-items-center h-100">
                    <div class="row text-center h-100 align-self-stretch">
                        <div class="col-12">
                            <h6 class="font-weight-bold mt-1">الباقات</h6>
                            <div class="mt-4">
                                <button 
                                    type="button"
                                    class="btn pt-0 btn-outline-dark hover-shadow rounded-pill w-100 font-weight-bold"
                                    data-bs-toggle="modal"
                                    data-bs-target="#changePackageModal"
                                    (click)="showChangePackageModal = true"
                                >
                                    تغيير الباقة
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-renew-modal [isActive]="showRenewModal" [currentQuotaDescription]="currentQuotaDescription" [currentQuotaInformation]="quotaInformation" [userBalance]="userProfile['balance']" (isClosed)="ModalClosedEvent($event)" (refreshParent)="refreshDashboard(true)"></app-renew-modal>
<app-change-package-modal [quotaInformation]="quotaInformation" [isActive]="showChangePackageModal" [currentQuotaDescription]="currentQuotaDescription" (isClosed)="ModalClosedEvent($event)" (refreshParent)="refreshDashboard(true)" (openRenuewModal)="openRenewModal()"></app-change-package-modal>
<app-offers-modal [profileId]="quotaInformation?.profile_id" [isActive]="showOffersModal" (isClosed)="ModalClosedEvent($event)" (refreshParent)="refreshDashboard(true)"></app-offers-modal>
<app-redeem-modal [isActive]="showRedeemModal" (isClosed)="ModalClosedEvent($event)" (refreshParent)="refreshDashboard(true)"></app-redeem-modal>
