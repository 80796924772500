<div class="row mb-5">
  <div class="col-lg-12">
    <div
      class="card shadow-sm hover-shadow transition-fast rounded-lg mb-5 overflow-hidden"
    >
      <div class="card-body p-5 px-0 p-lg-0">
        <div class="tickets-container h-auto">
          <div class="tickets-list bg-white">
            <div class="container p-5 px-0 px-lg-4 py-lg-4">
              <div class="row">
                <div class="col-12 text-start" style="height: 1%">
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <h4
                          class="font-weight-bold text-center text-lg-start mb-4"
                        >
                          الدعـم الفنـي
                        </h4>
                        <button
                          title="إرسال رسالة"
                          class="btn btn-sm btn-outline-dark rounded-lg px-5 mt-1 add-ticket hover-shadow transition-fast"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-container>
                  <div class="col-12">
                    <div
                      class="overflow-y-auto position-absolute custom-scroll"
                      style="bottom: 0; height: 80%"
                    >
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-12 py-3 tickets-list-item">
                            <a [routerLink]="'/help/tickets/1'" class="link-dark">
                              <div class="row">
                                <div class="col-10">
                                  <div class="text-start">
                                    <h6 class="mb-0">كيف يمكنني تعبئة الرصيد؟</h6>
                                    <small
                                      class="text-black-50"
                                      style="font-size: 0.7em"
                                      ><span dir="ltr"
                                        >2022-05-05 16:22:05</span
                                      ></small
                                    >
                                  </div>
                                </div>
                                <div class="col-2">
                                  <span
                                    title="لم يتم الحل"
                                    class="material-icons text-warning"
                                    style="font-size: medium"
                                    >circle</span
                                  >
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="chat bg-gray-dark">
            <div class="container p-5 px-0 px-lg-4 py-lg-4 h-100">
              <ng-container *ngIf="isChatEmpty">
                <div class="w-100 h-100 d-flex justify-content-center align-items-center">
                  <div>
                    <img src="assets/img/empty.png" alt="لا توجد مراسلات.">
                    <h6 class="text-muted"><small>لا توجد رسائل </small></h6>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!isChatEmpty">
                <div class="row">
                  <div class="col-12 text-start">
                    <div class="container">
                      <div class="row">
                        <div class="col-12">
                          <h4
                            class="font-weight-bold text-center text-lg-start mb-4"
                          >
                          <span title="الرقم المميز لتذكرة الدعم الفني">
                            #12345
                          </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="position-relative" style="height: 70vh">
                      <div
                        class="overflow-y-auto position-absolute custom-scroll w-100"
                        style="top: 0%; height: 80%"
                      >
                        <div class="container-fluid">
                          <div class="row">
                            <div class="col-10">
                              <div class="card hover-shadow my-4 w-fit-content px-3 ms-auto" style="border-radius: 15px;">
                                <div class="card-body py-2">
                                  <div class="text-end">
                                    <h6 class="mb-0">كيف يمكنني تعبئة الرdsfsdصيد؟</h6>
                                    <small
                                      class="text-black-50"
                                      style="font-size: 0.7em"
                                      ><span dir="ltr"
                                        >2022-05-05 16:22:05</span
                                      ></small
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-2">
                              <div class="h-100 w-100 d-flex align-items-center">
                                <img
                                  src="assets/img/alofoq_logo_icon_ square.png"
                                  class="img-fluid rounded-circle hover-shadow transition-fast"
                                  width="50"
                                  title="الأفق الرقمي"
                                  alt="الأفق الرقمي"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <div
                        class="overflow-y-auto position-absolute custom-scroll w-100 d-flex align-items-center"
                        style="bottom: 0; height: 25%"
                      >
                        <div class="container-fluid">
                          <div class="row">
                            <div class="col-12">
                              <div class="input-group hover-shadow mt-4" style="border-radius: 15px;">
                                <input
                                  type="text"
                                  class="form-control border-end-0 py-3"
                                  placeholder="اكتب رسالة ..."
                                  style="border-radius: 0 15px 15px 0;"
                                />
                                <span
                                  class="input-group-text bg-white"
                                  id="basic-addon1"
                                  style="border-radius: 15px 0 0 15px;"
                                >
                                  <button
                                    class="btn btn-sm btn-success-light hover-shadow transition-fast px-3 pt-0 rounded-3 text-light"
                                    type="button"
                                    id="button-addon1"
                                  >
                                    إرسال
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>              
              <ng-container *ngIf="!isChatEmpty">
                <div class="row">
                  <div class="col-12 text-start">
                    <div class="container">
                      <div class="row">
                        <div class="col-12">
                          <h4
                            class="font-weight-bold text-center text-lg-start mb-4"
                          >
                          <span title="الرقم المميز لتذكرة الدعم الفني">
                            #12345
                          </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="position-relative" style="height: 70vh">
                      <div
                        class="overflow-y-auto position-absolute custom-scroll w-100"
                        style="top: 0%; height: 80%"
                      >
                        <div class="container-fluid">
                          <div class="row">
                            <div class="col-10">
                              <div class="card hover-shadow my-4 w-fit-content px-3 ms-auto" style="border-radius: 15px;">
                                <div class="card-body py-2">
                                  <div class="text-end">
                                    <h6 class="mb-0">كيف يمكنني تعبئة الرصيد؟</h6>
                                    <small
                                      class="text-black-50"
                                      style="font-size: 0.7em"
                                      ><span dir="ltr"
                                        >2022-05-05 16:22:05</span
                                      ></small
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-2">
                              <div class="h-100 w-100 d-flex align-items-center">
                                <img
                                  src="assets/img/alofoq_logo_icon_ square.png"
                                  class="img-fluid rounded-circle hover-shadow transition-fast"
                                  width="50"
                                  title="الأفق الرقمي"
                                  alt="الأفق الرقمي"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row" dir="ltr">
                            <div class="col-10">
                              <div class="card hover-shadow my-4 w-fit-content px-3 me-auto" style="border-radius: 15px;">
                                <div class="card-body py-2">
                                  <div class="text-end">
                                    <h6 class="mb-0">كيف يمكنني تعبئة الرصيد؟</h6>
                                    <small
                                      class="text-black-50"
                                      style="font-size: 0.7em"
                                      ><span dir="ltr"
                                        >2022-05-05 16:22:05</span
                                      ></small
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-2">
                              <div class="h-100 w-100 d-flex align-items-center">
                                <img
                                  src="assets/img/profile_picture.png"
                                  class="img-fluid rounded-circle hover-shadow transition-fast"
                                  width="50"
                                  title="الأفق الرقمي"
                                  alt="الأفق الرقمي"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="overflow-y-auto position-absolute custom-scroll w-100 d-flex align-items-center"
                        style="bottom: 0; height: 25%"
                      >
                        <div class="container-fluid">
                          <div class="row">
                            <div class="col-12">
                              <div class="input-group hover-shadow mt-4" style="border-radius: 15px;">
                                <input
                                  type="text"
                                  class="form-control border-end-0 py-3"
                                  placeholder="اكتب رسالة ..."
                                  style="border-radius: 0 15px 15px 0;"
                                />
                                <span
                                  class="input-group-text bg-white"
                                  id="basic-addon1"
                                  style="border-radius: 15px 0 0 15px;"
                                >
                                  <button
                                    class="btn btn-sm btn-success-light hover-shadow transition-fast px-3 pt-0 rounded-3 text-light"
                                    type="button"
                                    id="button-addon1"
                                  >
                                    إرسال
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

        </div>
        <!-- <ng-container *ngIf="isLoading">
                      <div class="d-flex justify-content-center align-items-center my-5">
                          <div class="spinner-grow" style="width: 2rem; height: 2rem;" role="status">
                              <span class="sr-only">Loading...</span>
                          </div>
                      </div>
                  </ng-container>                        -->
        <!-- <ng-container *ngIf="!isLoading">

                  </ng-container> -->
      </div>
    </div>
  </div>
</div>

<!-- <div class="row mb-5">
  <div class="col-lg-12">
    <div class="card shadow-sm hover-shadow transition-fast rounded-lg mb-5">
      <div class="card-body py-0">
        <div class="row">
          <div class="col-6 bg-dark rounded-lg">
            sss
          </div>
          <div class="col-6 bg-success rounded-lg">
            aaa
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
