import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-owl-slider',
  templateUrl: './owl-slider.component.html',
  styleUrls: ['./owl-slider.component.scss']
})
export class OwlSliderComponent implements OnInit {

  @Input() props: {data: [], margin: number, mobileScreenstItems: number, tabletScreensItems: number, computerScreensItems: number};

  slides: [];
  customOptions: OwlOptions;

  constructor() { }

  ngOnInit(): void {
    this.slides = this.props.data;
    this.customOptions =  {
      rtl: true,
      lazyLoad:true,
      margin: this.props.margin,
      loop: true,
      animateOut: true,
      animateIn: true,
      autoplay: true,
      center: false,
      dots: true,
      autoHeight: true,
      autoWidth: true,
      responsive: {
        0: {
          items: this.props.mobileScreenstItems,
        },
        481: {
          items: this.props.tabletScreensItems,
        },
        1025: {
          items: this.props.computerScreensItems,
        }
      }
    }    
  }



}
