import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatBytes'
})
export class FormatBytesPipe implements PipeTransform {

  transform(value, ...args: unknown[]): unknown {
      let digits = 2
      let result = ''
      if(value == 0) {
        return "0"+"|*|"+"GB"
      } else if(value > 0) {
        const x = 0 > digits ? 0 : digits,d=Math.floor(Math.log(value)/Math.log(1024));
        
        // result = parseFloat((value/Math.pow(1024,d)).toFixed(x))+"|*|"+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d] 
        result = parseFloat((value/Math.pow(1024,d)).toFixed(x))+"|*|"+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d] 
      } else {
        result = "0"+"|*|"+"GB"
      }

    return result;
  }

}
