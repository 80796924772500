import { TrafficService } from './../../services/resources/traffic.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { FormatBytesPipe } from 'src/app/pipes/format-bytes.pipe';
// import { fromEvent, Observable, Subscription } from "rxjs";
// import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-usage-log',
  templateUrl: './usage-log.component.html',
  styleUrls: ['./usage-log.component.scss']
})
export class UsageLogComponent implements OnInit {

  isLoading: boolean = false

  report_type: string = "daily"
  selectedMonth: string = "1"
  selectedYear: string = ""

  currentYear: string = ""
  currentMonth: string = ""

  years: number[] = []

  tableData: any = []
  chartData: any = []

  subscription: Subscription

  // TODO: EXTRA FEATURE: years, months and days determination could be calculated dynamically from the subscribtion year until the current date  

  constructor(private trafficService: TrafficService, private formatBytesPipe: FormatBytesPipe) { 
    this.getCurrentMonthAndYear()
    this.selectedMonth = this.currentMonth
    this.fillYearsArray()
    this.selectedYear = this.currentYear
    this.getData()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  getCurrentMonthAndYear() {
    const d = new Date()    
    this.currentMonth = (d.getMonth() + 1 ).toString()
    this.currentYear = d.getFullYear().toString()
  }

  fillYearsArray() {
    let startingYear = 2018;
    for(let i = Number(this.currentYear); i >= startingYear; i--) {
      this.years.push(i)
    }
  }

  getData() {
    let requestBody = {"report_type":this.report_type,"month":this.selectedMonth,"year":this.selectedYear,"user_id":null}    
    this.isLoading = true
    this.tableData = []
    this.chartData = []
    
    this.subscription = this.trafficService.post(requestBody)
    .pipe(take(1), finalize(() => this.isLoading = false))
    .subscribe(
      res => {
        let tempDataArray = <any>Object.entries(res['data'])
        
        for(let i = 0; i < tempDataArray[0][1].length; i++) {
          let day = i + 1
          let rx = Number(tempDataArray[0][1][i])
          let tx = Number(tempDataArray[1][1][i])
          let total = Number(tempDataArray[2][1][i])
          let total_real = Number (tempDataArray[3][1][i])
          let free_traffic = Number(tempDataArray[4][1][i])
                    
          let freeTraffic = total_real - total
          let currentRow = {day: day,rx:rx,tx:tx,total:total,total_real:total_real,free_traffic: (freeTraffic < 1024 ? '0' : freeTraffic)}

          
          
          // console.log(currentRow);
          // console.log("Free Traffic: " + free_traffic);
          
          
          // console.log("Day " + day + ": ", rx + tx + total + free_traffic);

          // console.log(this.formatBytesPipe.transform(currentRow));
          // console.log(currentRow);
          
          this.chartData.push(currentRow)
          
          if(rx + tx + total + total_real + free_traffic != 0) {
            this.tableData.push(currentRow)
          }
        }
        // console.log(this.tableData);
        
      }
    )
  }


  // Commented by Rida O. Labbar at 12:11PM - August 11, 2021
  // There is no time for converting tables to cards on small screens right now :|

  // resizeObservable$: Observable<Event>
  // resizeSubscription$: Subscription
  
  ngOnInit(): void {
    // this.onWindowResize()
  }

  // checkIfSmallScreen() {

  // }

  // onWindowResize() {
  //   this.resizeObservable$ = fromEvent(window, 'resize')
  //   this.resizeSubscription$ = this.resizeObservable$.pipe(debounceTime(1000)).subscribe(evt => {
  //     console.log(evt.currentTarget['innerWidth'])
  //   })
  // }

  // ngOnDestroy() {
  //   this.resizeSubscription$.unsubscribe()
  // }
}

