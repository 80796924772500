import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss']
})
export class PieComponent implements OnInit {

  @Input() percentage: number;

  single: any[] = [
    {
      "name": "المستخدم",
      "value": 0
    },
    {
      "name": "المتبقي",
      "value": 0
    }
  ];
  view: any[] = [];

  // options
  showLegend: boolean = true;
  showLabels: boolean = true;
  doughnut: boolean = true;
  arcWidth = 0.12
  gradient: boolean = true;

  colorScheme = {
    domain: ['#dde2e5', '#5AA454']
  };

  constructor() { 
    this.calculateChartSize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['percentage']['currentValue'] != null) {      
      this.calculateChartData()
    }
  }
  
  onResize(event) {
    this.calculateChartSize();
  }

  calculateChartData() {

    if(Number(this.percentage) < 0) {
      this.percentage = 0
    }

    this.single[0]['value'] = 100 - Number(this.percentage)
    this.single[1]['value'] = Number(this.percentage)    
  }

  calculateChartSize() {
    // Mobile
    if (innerWidth < 700) {
      this.view = [innerWidth / 3, innerHeight / 7];
    }
    // Tablet
    else if (innerWidth >= 700 && innerWidth < 1024) {
      this.view = [innerWidth / 4, innerHeight / 8];
    }
    // PC
    else if (innerWidth >= 1024 && innerWidth < 1600) {
      // this.view = [innerWidth / 4, innerHeight / 4.6];
      this.view = [innerWidth / 4, innerHeight / 5.3];
    }
    // PC- X-large screen
    else if (innerWidth >= 1600) {
      // this.view = [innerWidth / 9, innerHeight / 7];
      this.view = [innerWidth / 9, innerHeight / 5.8];
    } 
    else {
      this.view = [innerWidth / 10, innerHeight / 5]
    }
  }
  
  ngOnInit(): void {
  }

}
